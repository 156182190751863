export const NAMESPACE = 'pageHeader';

export const MUTATION_TYPES = {
  toggleNav: 'toggleNav',
  toggleSubMenu: 'toggleSubMenu',
  setMeta: 'setMeta'
};

/**
 * @module page-header
 *
 * this module handles state revolving the page header and
 * it's mutations / actions
 */

const state = {
  open: false,
  subMenuOpen: false,
  metaActive: false
};

const mutations = {
  [MUTATION_TYPES.toggleNav](state, isOpen) {
    if (typeof(isOpen) === 'undefined') {
      state.open = !state.open;
      return;
    }

    state.open = isOpen;
  },

  [MUTATION_TYPES.toggleSubMenu](state, isOpen) {
    if (typeof(isOpen) === 'undefined') {
      state.subMenuOpen = !state.subMenuOpen;
      return;
    }

    state.subMenuOpen = isOpen;
  },

  [MUTATION_TYPES.setMeta](state, isActive) {
    state.metaActive = isActive;
  }
};

export default {
  state,
  mutations,
  namespaced: true
};
