import Vue from 'vue';
import axios from 'axios';
import {get,testLocalStorage} from '../../base/util';
import {
  NAMESPACE as modalNamespace,
  ACTION_TYPES as modalActions
} from './modals';

// apply overrides if exists.
let overrides = typeof(apiOverrides) !== 'undefined' ? apiOverrides : {};
let { MESSAGES } = overrides; 

export const NAMESPACE = 'messages';

export const MUTATION_TYPES = {
  prependMessage: 'prependMessage',
  addMessage: 'addMessage',
  addMessages: 'addMessages',
  attachMessageTimer: 'attachMessageTimer',
  updateMessage: 'updateMessage',
  resetMessageTimeout: 'resetMessageTimeout',
  dismissMessage: 'dismissMessage',
  setFetchingMessages: 'setFetchingMessages',
};

export const ACTION_TYPES = {
  updateMessage: 'updateMessage',
  showMessage: 'showMessage',
  startMessageTimer: 'startMessageTimer',
  fetchContextMessages: 'fetchContextMessages'
};

let msgCounter = 0;

const state = {
  fetchingMessages: false,
  messages: []
};

const hasLocalStorage = testLocalStorage();
const MESSAGES_KEY = 'sui-seen-messages';

/**
 * Gets all the message ids the current user has seen and 
 * been dismissed. 
 * @returns {Array} array of message ids.
 */
function getSeenMessageIds() {
  let messages = [];
  let json = localStorage.getItem(MESSAGES_KEY);
  if (json) {
    try {
      messages = JSON.parse(json);
      if (!Array.isArray(messages)) {
        messages = [];
      }
    }
    catch(error) {}
  }
  return messages;
}

function flagMessageAsSeen(id) {
  if (!hasLocalStorage) { return; }
  let messages = getSeenMessageIds();
  if (!messages.includes(id)) {
    messages.push(id);
    localStorage.setItem(MESSAGES_KEY, JSON.stringify(messages));
  }
}

const mutations = {
  [MUTATION_TYPES.setFetchingMessages](state, fetching) {
    state.fetchingMessages = fetching;
  },

  [MUTATION_TYPES.prependMessage](state, message) {
    delete message.prepend;
    state.messages.splice(0, 0, message);
  },

  [MUTATION_TYPES.addMessage](state, message) {
    state.messages.push(message);
  },

  [MUTATION_TYPES.addMessages](state, messages) {
    state.messages.push(...messages);
  },

  [MUTATION_TYPES.attachMessageTimer](state, { id, timer }) {
    var msg = state.messages.find(m => m.id === id);
    msg.$timer = timer;
  },

  [MUTATION_TYPES.updateMessage](state, { id, message }) {
    let index = state.messages.findIndex(msg => msg.id === id);
    if (index < 0) { return; }
    var msg = state.messages[index];
    Vue.set(state.messages, index, { ...msg, ...message });
  },

  [MUTATION_TYPES.resetMessageTimeout](state, { id }) {
    var msg = state.messages.find(msg => msg.id === id);
    clearTimeout(msg.$timer);
  },

  [MUTATION_TYPES.dismissMessage](state, { id }) {
    let index = state.messages.findIndex(msg => msg.id === id);
    if (index < 0) { return; }
    var msg = state.messages[index];
    clearTimeout(msg.$timer);
    state.messages.splice(index, 1);
    if (msg.dismissedPermanently) {
      flagMessageAsSeen(msg.id);
    }
  }
};

const actions = {
  [ACTION_TYPES.updateMessage]({ dispatch, commit }, ctx) {
    commit(MUTATION_TYPES.updateMessage, ctx);
    if (ctx.message.timeToLive) {
      return dispatch(ACTION_TYPES.startMessageTimer, {id:ctx.id});
    }
  },

  /**
   * Fetches via the api, if there are any messages to show for the current user
   * 
   * @param {Object} context the vuex context
   * @param {Object} payload the payload
   * @param {String} payload.pageId the id of the current page
   * @param {String} payload.adminMode setting this to true will make `dismissedPermanently` not be taken into account. 
   */
  [ACTION_TYPES.fetchContextMessages]({ dispatch, commit }, { pageId, adminMode }) {
    commit(MUTATION_TYPES.setFetchingMessages, true);
    let url = MESSAGES || '/api/v1/messages';
    return axios
      .get(url, { params: { pageId }})
      .then(response => {
        let seenMessages = getSeenMessageIds();
        let messages = get(response, 'data.result.messages');
        let notSeenMessages = messages.filter((msg) => {
          if (!hasLocalStorage) { return true; }
          if (!msg.dismissedPermanently || adminMode) { return true; }
          return !seenMessages.includes(msg.id);
        });

        for (const msg of notSeenMessages) {
          dispatch(ACTION_TYPES.showMessage, msg);
        }

        // The modals are fetched in the "messages" call since they are in a way
        // messages, however, modal state is handled in its own store.
        let modals = get(response, 'data.result.modals') || [];
        if (modals && Array.isArray(modals) && modals.length) {
          dispatch(`${modalNamespace}/${modalActions.showMessageModals}`, { modals, adminMode }, { root: true });
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        commit(MUTATION_TYPES.setFetchingMessages, false);
      });
  },

  [ACTION_TYPES.showMessage]({ dispatch, commit }, message) {
    if (!message.id) {
      message.id = ++msgCounter;
    }

    // for messages in loading state, no sense in
    // making them timeout automatically disapear...
    if (message.loading) {
      message.timeToLive = 0;
    }

    // ... or no timeToLive supplied at all?
    else if (!message.hasOwnProperty('timeToLive')) {
      message.timeToLive = 5000;
    }

    let mutation = message.prepend 
      ? MUTATION_TYPES.prependMessage 
      : MUTATION_TYPES.addMessage;

    commit(mutation, {
      loading: false,
      type: 'success',
      removeable: true,
      ...message
    });

    let dismissedPromise;
    // if the message has a limited time to live
    // we start the timer. However, put it last on the stack
    // though, so we let it render first.
    if (message.timeToLive > 0) {
      dismissedPromise = dispatch(ACTION_TYPES.startMessageTimer, { id: message.id });
    }

    return message.id;
  },

  [ACTION_TYPES.startMessageTimer]({ state, commit }, { id }) {
    let msg = state.messages.find(m => m.id === id);
    if (!msg || msg.timeToLive <= 0) { return; }
    return new Promise((resolve, reject) => {
      let timer = setTimeout(() => {
        commit(MUTATION_TYPES.dismissMessage, {id});
        resolve(id);
      }, msg.timeToLive);
      commit(MUTATION_TYPES.attachMessageTimer, {id,timer});
    });
  }
};

export default {
  state,
  mutations,
  actions,
  namespaced: true
};