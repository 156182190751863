import inputBlock from './input-block.vue';
import {debounce} from '../../base/util.js';

/**
 * validating form
 * this is a *mixin* that can be added to a component to make it a "validating form"
 * that checks via javascript as the user writes. The vue component with the same name is
 * just when you need a validating form with no additional functionality.
 */

export default {
  props: {
    /**
     * Setting this to true will cause the form to issue a full silent validation
     * when any input block has changed.
     */
    fullValidationOnChange: { type: Boolean, default: false },
    /**
     * If this is not set to true, the form (unless you manually stop it) will submit
     * (Classic style) if it is valid.
     */
    alwaysPrevent: { type: Boolean, default: false }
  },

  data() {
    return {
      inputBlock,
      submitted: false,
      valid: false,
      validating: false
    }
  },

  watch: {
    valid(isValid) {
      this.$emit('valid-state-changed', isValid);
    }
  },

  mounted() {
    this.$on('input-block-validated', this.inputBlockValidated);
  },

  computed: {
    cssClasses() {
      return {
        'validating-form--pristine'  : !this.submitted,
        'validating-form--invalid'   : !this.valid && this.submitted,
        'validating-form--valid'     : this.valid && this.submitted,
        'validating-form--validating': this.validating
      };
    }
  },

  methods: {
    inputBlocks() {
      return this.$children.filter(child => child.$options.name === 'input-block');
    },

    /**
     * Whenever an input block of the form has been validated, this
     * function is called.
     * @param {InputBlock} inputBlock 
     */
    inputBlockValidated() {
      if (this.fullValidationOnChange) {
        this.validate({ silent: true });
      }
    },

    /**
     * Just delegates to the debounced version.
     * @param {Object} options validation options
     */
    validate({ fromSubmit, silent }) {
      this.validating = true;

      // Validate all input blocks, validators can be async
      // so they validate in a promise..
      let promises = this.inputBlocks()
        .map(ib => ib.validate(fromSubmit, silent));

      // ... when all is done, let the form reflect
      return Promise
        .all(promises)
        .then(validities => {
          this.valid = validities.reduce((x, y) => x && y, true);
          this.validating = false;
          return this.valid;
        });
    },

    /**
     * Checks one specific fields valid state.
     * 
     * *NOTE*: Sending a field that isn't validated or doesn't exist will return true (valid).
     * @param {String} fieldName name of the field to check
     * @returns {Promise<Boolean>} a promise for boolean whether field is valid or not. 
     */
    getFieldValidState(fieldName) {
      var inputBlock = this.inputBlocks().find(block => block.fieldName === fieldName);
      if (!inputBlock) { return Promise.resolve(true); }
      return inputBlock.validate(false, false);
    },

    checkForm(evt) {
      this.submitted = true;
      if (evt) { evt.preventDefault(); }

      var validation = this
        .validate({ fromSubmit: true })
        .finally(() => {
          if (this.valid && !this.alwaysPrevent) {
            this.$el.submit();
          }
        });

      this.$emit('form-submit', { evt, validation });
      return validation;
    }
  }
};
