import axios from 'axios';

/**
 * products-list
 * this vuex module contain state, mutations and actions for
 * mainly the products list component.
 */

const namespaced = true;

// apply overrides if exists.
let overrides = typeof(apiOverrides) !== 'undefined' ? apiOverrides : {};
let { PRODUCTS_META, PRODUCTS } = overrides;

const state = {
  metaEndpoint        : PRODUCTS_META || '/api/v1/products-meta',
  productsEndpoint    : PRODUCTS || '/api/v1/products',
  operationsRunning   : 0,
  sorting             : false,
  fetching            : false,
  products            : [],
  onlyOwned           : false,
  onlySoldOnWebsite   : true,
  pageNumber          : 1,
  pageSize            : 20,
  query               : null,
  totalResults        : 0,
  metaSettings        : {
    tagGroups: [],
    sortingOptions: []
  },
  categoryId: null,
  calculatePrice: true
};

const mutations = {
  setQuery(state, query) {
    state.query = query;
  },

  operationStarted(state) {
    state.operationsRunning++;
  },

  operationEnded(state) {
    state.operationsRunning--;
  },

  sortingStarted(state) {
    state.sorting = true;
  },

  sortingEnded(state) {
    state.sorting = false;
  },

  fetchingStarted(state) {
    state.fetching = true;
  },

  fetchingEnded(state) {
    state.fetching = false;
  },

  setMetaSettings(state, metaSettings) {
    metaSettings.tagGroups.forEach(group => group.activeOptions = []);

    let defaultSort = metaSettings.sortingOptions.filter(option => !!option.default)[0];
    if (!defaultSort) defaultSort = metaSettings.sortingOptions[0];

    defaultSort.active = true;
    state.metaSettings.tagGroups = metaSettings.tagGroups;
    state.metaSettings.sortingOptions = metaSettings.sortingOptions;
  },

  setActiveSortingOption(state, activeSortingOption) {
    state.metaSettings.sortingOptions
      .forEach(opt => opt.active = (opt === activeSortingOption));
  },

  setActiveSortingOptionById(state, activeSortingOptionId) {
    state.metaSettings.sortingOptions
      .forEach(opt => opt.active = (opt.id === activeSortingOptionId));
  },

  setActiveFilters(state, { id, activeOptions }) {
    state.metaSettings.tagGroups
      .filter(group => group.id == id)
      .forEach(group => group.activeOptions = activeOptions);
  },

  clearActiveFilters(state) {
    state.metaSettings.tagGroups.forEach(group => group.activeOptions = []);
  },

  setPageSize(state, pageSize) {
    state.pageSize = pageSize;
  },

  setPageNumber(state, pageNumber) {
    state.pageNumber = pageNumber;
  },

  setOnlySoldOnWebsite(state, only) {
    state.onlySoldOnWebsite = only;
  },

  setTotalResults(state, totalResults) {
    state.totalResults = totalResults;
  },

  setProducts(state, products) {
    state.products = products;
  },

  addProducts(state, products) {
    state.products.push(...products);
  },

  setCategoryId(state, categoryId) {
    state.categoryId = categoryId;
  }
};

const getters = {
  /**
   * returns whether there is more results to load,
   * it will return false if no results have loaded yet.
   */
  hasMoreResults(state) {
    if (!state.totalResults) { return false; }
    let totalPages = Math.ceil(state.totalResults / state.pageSize);
    return state.pageNumber < totalPages;
  },

  activeSortingOption(state) {
    return state.metaSettings.sortingOptions.filter(opt => opt.active)[0];
  },

  activeFiltersCount(state) {
    return state.metaSettings.tagGroups
      .map(group => group.activeOptions.length)
      .reduce((prev, count) => prev + count, 0);
  }
};

let filterCancelToken, productsCancelToken;

const actions = {
  /**
   * this function fetches the initial values
   */
  init({ dispatch, commit }, options) {
    let { categoryId, sortingOptionId } = options;
    commit('setCategoryId', categoryId);
    dispatch('fetchMeta').then(() => {
      if (sortingOptionId) {
        commit('setActiveSortingOptionById', sortingOptionId);
      }
      dispatch('fetchProducts');
    });
  },

  /**
   * fetches the Meta for the products.
   */
  fetchMeta({commit, state}) {
    commit('operationStarted');

    if (filterCancelToken) {
      filterCancelToken();
      filterCancelToken = null;
    }

    return axios.get(state.metaEndpoint, {
      cancelToken: new axios.CancelToken(c => filterCancelToken = c)
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    })
    .then(response => {
      if (!response) { return; }
      commit('setMetaSettings', response.data);
    })
    .finally(() => {
      commit('operationEnded');
      filterCancelToken = null;
    });
  },

  /**
   * fetches the products.
   */
  fetchProducts({commit, state, getters}, options) {
    let { emptyProducts } = options || {};
    let tagGroups = state.metaSettings.tagGroups || [];
    let sortingOptions = state.metaSettings.sortingOptions || [];

    commit('operationStarted');

    if (productsCancelToken) {
      productsCancelToken();
      productsCancelToken = null;
    }

    let params = {
      text: state.query,
      pageNumber: state.pageNumber,
      pageSize: state.pageSize
    };

    let activeSort = sortingOptions.filter(opt => opt.active)[0];
    if (activeSort) {
      params.sort = activeSort.id;
    }

    tagGroups.forEach(group => {
      if (group.activeOptions.length) {
        params[group.id] = group.activeOptions.join(',')
      }
    });

    if (state.categoryId) {
      params.categoryId = state.categoryId;
    }

    if (state.onlySoldOnWebsite) {
      params.onlySoldOnWebsite = state.onlySoldOnWebsite;
    }

    if (state.calculatePrice)
      params.calculatePrice = state.calculatePrice

    return axios.get(state.productsEndpoint, {
      cancelToken: new axios.CancelToken(c => productsCancelToken = c),
      params
    })
      .then(response => {
        if (!response) { return; }
        let productsMutation = emptyProducts ? 'setProducts' : 'addProducts';
        commit(productsMutation, response.data.result);
        commit('setTotalResults', response.data.totalResults || 0);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      })
      .finally(() => {
        commit('fetchingEnded');
        commit('operationEnded');
        productsCancelToken = null;
      });
  },

  setActiveFilters({dispatch, commit}, { id, activeOptions }){
    commit('setPageNumber', 1);
    commit('setActiveFilters', { id, activeOptions });
    return dispatch('fetchProducts', { emptyProducts: true });
  },

  clearActiveFilters({dispatch, commit}) {
    commit('setPageNumber', 1);
    commit('clearActiveFilters');
    return dispatch('fetchProducts', { emptyProducts: true });
  },

  setActiveSortingOption({dispatch, commit}, activeSortingOption) {
    commit('setActiveSortingOption', activeSortingOption);
    commit('setPageNumber', 1);
    commit('sortingStarted');
    return dispatch('fetchProducts', { emptyProducts: true }).then(() => {
        commit('sortingEnded');
      });
  },

  loadMore({dispatch, state, commit}) {
    if (state.fetching) return;

    commit('fetchingStarted');
    return dispatch('debouncedLoadMore');
  },

  debouncedLoadMore({dispatch, state, commit}) {
    commit('setPageNumber', state.pageNumber+1);
    return dispatch('fetchProducts');
  },

  setCategoryId({ commit }, categoryId) {
    commit('setcategoryId', categoryId);
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced
};
