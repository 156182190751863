
/**
 * Tracks an event in the datalayer when a product has been
 * redeemed.  
 * 
 * @param {Object} param0 data
 * @param {Object} param0.product the product to track.
 */
export default function trackProductRedeem({ product }) {
  if (typeof(dataLayer) === 'undefined') { return; }
  if (!product) { return; }

  dataLayer.push({
    'event': 'productRedeem',
    'eventCategory': 'Product Redeemed',
    'eventAction': product.name
  });
}