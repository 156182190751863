import Vue             from 'vue';
import Vuex            from 'vuex';
import newsletter      from './modules/newsletter';
import support         from './modules/support';
import productsList    from './modules/products-list';
import cookieConsent   from './modules/cookie-consent';
import contents        from './modules/contents';
import account         from './modules/account';
import licenses        from './modules/licenses';
import inputValidation from './modules/input-validation';
import checkout        from './modules/checkout';


import {
  default as modals,
  NAMESPACE as modalsNamespace 
} from './modules/modals';

import { 
  default as pageHeader, 
  NAMESPACE as pageHeaderNamespace
} from './modules/page-header';

import { 
  default as messages, 
  NAMESPACE as messagesNamespace
} from './modules/messages';

import {
  default as shoppingCart,
  NAMESPACE as shoppingCartNamespace
} from './modules/shopping-cart';


Vue.use(Vuex);

/**
 * @module store
 * this module is the main file of the applications
 * vuex state.
 */
export default new Vuex.Store({
  strict: true,
  modules: {
    [pageHeaderNamespace]: pageHeader,
    newsletter,
    support,
    productsList,
    cookieConsent,
    [shoppingCartNamespace]: shoppingCart,
    contents,
    account,
    licenses,
    inputValidation,
    [modalsNamespace]: modals,
    [messagesNamespace]: messages,
    checkout
  }
});
