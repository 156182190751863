
/**
 * This construct contains helpers for
 * tracking events etc. with google analytics or
 * other trackers we might be using.
 */

let ecomLoaded = false;

export function trackEvent() {
  // if we find a google analytics tracker object,
  // use that to track an event.
  if (typeof(ga) === 'function') {
    ga.apply(this, ['send', 'event', ...arguments]);
  }
}

/**
 * tracks an ecommerce transaction. The data expected is in the format
 * of google tag manager; https://support.google.com/tagmanager/answer/6107169 but this
 * function however tracks via analytics.js. The plan was to support both systems, because it is
 * possible to switch in Litium.
 */
export function trackTransaction(transactionData) {
  if (typeof(ga) !== 'function') {
    return;
  }

  if (!ecomLoaded) {
    ga('require', 'ecommerce');
    ecomLoaded = true;
  }

  ga('ecommerce:addTransaction', {
    'id': transactionData.transactionId,
    'revenue': transactionData.transactionTotal,
    'tax': transactionData.transactionTax
  });

  let items = transactionData.transactionProducts || [];
  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    ga('ecommerce:addItem', {
      'id': transactionData.transactionId,
      'name': item.name,
      'sku': item.sku,
      'price': item.price,
      'quantity': item.quantity
    });
  }

  // finish up the order, and send it to be tracked.
  ga('ecommerce:send');
}
