
/**
 * This is just a mixin for being able to convert the format the api
 * gives for responsive images. This function converts that to a proper "data-bgset" format
 * that lazysizes wants.
 */

export default {
  methods: {
    toBgSet(imageInfo) {
        if (!imageInfo) { return ''; }
        return Object.keys(imageInfo)
            .map(key => `${imageInfo[key]} ${key}`)
            .join(', ');
    }
  }
};
