import accountSettings from './account-settings/account-settings.vue';
import myLicenses      from './my-licenses/my-licenses.vue';
import registerLicense from './register-license/register-license.vue';
import ilokAccount     from './ilok-account/ilok-account.vue';
import mySubscriptions from './my-subscriptions/my-subscriptions.vue';

/**
 * Creates the routes that applies to the user
 * depending on if he/she is a business user.
 */
export function createRoutes({isBusinessUser}) {
  let rootRedirect = isBusinessUser ? '/account-settings' : '/my-products';
  let routes = [
    {
      name: 'My Pages',
      path: '/',
      meta: { hideInMenu: true },
      redirect: rootRedirect
    },
    {
      path: '/account-settings',
      name: 'Account settings',
      meta: { icon: 'account-settings' },
      component: accountSettings
    }
  ];

  // non business users should be able to access more components.
  if (!isBusinessUser) {
    routes.splice(1, 0, {
      path: '/my-products',
      name: 'My products',
      meta: { icon: 'my-licenses' },
      component: myLicenses
    },
    {
      path: '/my-subscriptions',
      name: 'My subscriptions',
      meta: { icon: ''},
      component: mySubscriptions
    });
        
    routes.push(      
      {
        path: '/register-license',
        name: 'Register license',
        meta: { icon: 'register-license' },
        component: registerLicense
      },
      {
        path: '/ilok-account',
        name: 'iLok account',
        meta: { icon: 'ilok-account' },
        component: ilokAccount
      },      
    );
  }

  routes.push({
    path: '*',
    meta: { hideInMenu: true },
    redirect: rootRedirect
  });

  return routes;
}
