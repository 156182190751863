import {setCookie, getCookie} from 'tiny-cookie';

export const supportsLocalStorage = function() {
  var test = 'test';
  try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
  } catch(e) {
      return false;
  }
}();

/**
 * Deserialize and get the value found at the specified key
 * 
 * @param {String} key the key to serialize object into
 * @param {Object} options options
 * @param {Object} options.fallbackToCookie whether we should also try storing in cookie
 * @param {Object} options.defaultValue a fallback to return if key isn't set
 */
export function getDeserializeObject(key, { fallbackToCookie, defaultValue }) {
  let json = (!supportsLocalStorage && fallbackToCookie) 
    ? getCookie(key)
    : localStorage.getItem(key);

  if (!json) { return defaultValue || undefined; }
  try {
    return JSON.parse(json);
  }
  catch (error) {
    console.error(error);
    return defaultValue || undefined;
  }
}

export function setSerializedObject(key, obj, { fallbackToCookie }) {
  let json = JSON.stringify(obj);
  if (!supportsLocalStorage && fallbackToCookie) {
    setCookie(key, json, {expires:'1Y'});
  }

  localStorage.setItem(key, json);
}