import { mapMutations, mapState } from 'vuex';
import { NAMESPACE as ans } from '../../../store/modules/account';

export default {
  computed: {
    isSelectedMode() {
      return this.currentMode === this.mode;
    },
    currentMode: {
      get() { return this.$store.state.checkout.purchaseMode; },
      set(value) { this.setMode(value); }
    },
    ...mapState({
      signedIn: state => state[ans].signedIn
    })
  },

  methods: {
    ...mapMutations({
      setMode: 'checkout/setPurchaseMode'
    })
  }
};