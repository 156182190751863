import axios from 'axios';
import {get} from '../../base/util.js';
import trackProductRedeem from '../../base/analytics/track-product-redeem.js';
import {
  NAMESPACE as nsMessages,
  ACTION_TYPES as atMessages
} from './messages';

/**
 * licenses
 */

const namespaced = true;

// apply overrides if exists.
let overrides = typeof(apiOverrides) !== 'undefined' ? apiOverrides : {};
let { LICENCE_CODE, LICENSES, LICENSE_CODE_DEPOSIT, LICENSE_DEMO_DEPOSIT} = overrides;

const state = {
  licenseCodeToBeRedeemed: null,
  redeeming: false,
  productToBeRedeemed: {
    loaded: false,
    addingToAccount: false,
    id: null,
    featuredHighlight: null,
    name: null,
    images: {}
  },
  redeemError: {
    gotError: false,
    errorCode: 0,
    message: null
  },
  licenses: [],
  licensesCount: 0,
  loadingLicenses: false,
  depositingDemo: false
};

const mutations = {
  setLicenseCodeToBeRedeemed(state, licenseCode) {
    state.licenseCodeToBeRedeemed = licenseCode;
  },

  setRedeeming(state, redeeming) {
    state.redeeming = redeeming;
  },

  cancelRedeem(state) {
    state.redeeming = false;
    state.licenseCodeToBeRedeemed = null;
  },

  setProductToBeRedeemed(state, {id, featuredHighlight, name, images}) {
    state.productToBeRedeemed = {
      ...state.productToBeRedeemed,
      loaded: true,
      addingToAccount: false,
      id,
      featuredHighlight,
      name,
      images
    };
  },

  setRedeemError(state, {errorCode, message}) {
    state.redeemError.gotError = true;
    state.redeemError.errorCode = errorCode;
    state.redeemError.message = message;
  },

  clearRedeemError(state) {
    state.redeemError.gotError = false;
    state.redeemError.errorCode = 0;
    state.redeemError.message = null;
  },

  clearProductToBeRedeemed(state) {
    state.productToBeRedeemed = {
      ...state.productToBeRedeemed,
      loaded: false,
      addingToAccount: false,
      id: null,
      featuredHighlight: null,
      name: null,
      images: {}
    };
  },

  setLicenses(state, { totalResults, result }) {
    state.licensesCount = totalResults;
    state.licenses = result;
  },

  setLoadingLicenses(state, loading) {
    state.loadingLicenses = loading;
  },

  setDepositingDemo(state, depositing) {
    state.depositingDemo = depositing;
  },

  setAddingToAccount(state, adding) {
    state.productToBeRedeemed.addingToAccount = adding;
  }
};

const actions = {

  verifyLicenseCode({state, commit}) {
    commit('clearRedeemError');
    commit('clearProductToBeRedeemed');
    commit('setRedeeming', true);

    let endpoint = LICENCE_CODE || `/api/v1/license-codes/${state.licenseCodeToBeRedeemed}`;
    return axios
      .get(endpoint)
      .then(response => { commit('setProductToBeRedeemed', response.data.product); })
      .catch(error => { commit('setRedeemError', error.response.data); });
  },

  redeemLicenseCode({dispatch, state, commit}) {
    let redeemEndpoint = LICENSE_CODE_DEPOSIT || `/api/v1/license-codes/${state.licenseCodeToBeRedeemed}/deposit`;
    commit('setAddingToAccount', true);
    return axios
      .post(redeemEndpoint)
      .then(response => {
        commit('setRedeeming', false);
        let product = get(response, 'data.product');
        if (product) { trackProductRedeem({ product }); }
        return response.data;
      })
      .catch(error => { commit('setRedeemError', error.response.data); })
      .finally(() => {
        commit('setAddingToAccount', false);
      });
  },

  fetchLicenses({state, commit}) {
    commit('setLoadingLicenses', true);
    let endpoint = LICENSES || '/api/v1/licenses';

    return axios
      .get(endpoint)
      .then(response => {
        commit('setLicenses', response.data);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        commit('setLoadingLicenses', false);
      });
  },

  refreshLicenses({state, commit}) {
    commit('setLoadingLicenses', true);
    let endpoint = LICENSES || '/api/v1/licenses';

    return axios
      .get(endpoint + '?refresh=true')
      .then(response => {
        commit('setLicenses', response.data);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        commit('setLoadingLicenses', false);
      });
  },

  tryDemo({dispatch,commit,state}, productId) {
    commit('setDepositingDemo', true);
    let id;

    // since we're depositing a license that will pop a message,
    // display a loading flash message, grab a handle of
    // the id of the new message.
    dispatch(`${nsMessages}/${atMessages.showMessage}`, {
      loading: true,
      type: 'success',
      removeable: false
    }, {root:true})

      // when we have an id of our new message,
      // fire of the actual deposit
      .then(msgId => {
        id = msgId;
        let endpoint = LICENSE_DEMO_DEPOSIT || `/api/v1/licenses/${productId}/demo`
        return axios.post(endpoint);
      })

      // We've successfully deposited a license,
      // update our message
      .then(response => {
        let text = get(response, 'data.message');
        let myProductsUrl = get(response, 'data.data.myProductsUrl');

        let message =  {
          loading: false,
          icon: 'open-box',
          text,
          timeToLive: 5000,
          removeable: true,
          cta: {
            text: 'My Products',
            href: myProductsUrl
          }
        };

        return dispatch(`${nsMessages}/${atMessages.updateMessage}`, { id, message }, { root: true });
      })

      .catch(error => {
        let text = get(error, 'response.data.message');
        let cta = get(error, 'response.data.data.cta');

        let message = {
          loading: false,
          icon: 'info',
          type: 'callout',
          text,
          cta,
          removeable: true,
          timeToLive: 3000
        }

        return dispatch(`${nsMessages}/${atMessages.updateMessage}`, { id, message }, { root: true });
      })

      // we've reached here, flip the flag!
      .finally(() => {
        commit('setDepositingDemo', false);
      });
  },

  addFree({ dispatch, commit, state }, productId) {
      commit('setDepositingFree', true);
      let id;

      // since we're depositing a license that will pop a message,
      // display a loading flash message, grab a handle of
      // the id of the new message.
      dispatch(`${nsMessages}/${atMessages.showMessage}`, {
              loading: true,
              type: 'success',
              removeable: false
          }, { root: true })

          // when we have an id of our new message,
          // fire of the actual deposit
          .then(msgId => {
              id = msgId;
              let endpoint = LICENSE_DEMO_DEPOSIT || `/api/v1/licenses/${productId}/free`
              return axios.post(endpoint);
          })

          // We've successfully deposited a license,
          // update our message
          .then(response => {
              let text = get(response, 'data.message');
              let myProductsUrl = get(response, 'data.data.myProductsUrl');

              let message = {
                  loading: false,
                  icon: 'open-box',
                  text,
                  timeToLive: 5000,
                  removeable: true,
                  cta: {
                      text: 'My Products',
                      href: myProductsUrl
                  }
              };

              return dispatch(`${nsMessages}/${atMessages.updateMessage}`, { id, message }, { root: true });
          })

          .catch(error => {
              let text = get(error, 'response.data.message');
              let cta = get(error, 'response.data.data.cta');

              let message = {
                  loading: false,
                  icon: 'info',
                  type: 'callout',
                  text,
                  cta,
                  removeable: true,
                  timeToLive: 3000
              }

              dispatch(`${nsMessages}/${atMessages.updateMessage}`, { id, message }, { root: true });
          })

          // we've reached here, flip the flag!
          .finally(() => {
              commit('setDepositingFree', false);
          });
  }
};




export default {
  state,
  mutations,
  actions,
  namespaced
};
