import axios from 'axios';

/**
 * This mini module just contains a call to the api endpoint
 * that validates input.
 */

// apply overrides if exists.
let overrides = typeof(apiOverrides) !== 'undefined' ? apiOverrides : {};
let { INPUT_VALIDATION } = overrides;

const actions = {
  validate({commit, state}, { type, params }) {
    let uri = INPUT_VALIDATION || `api/v1/input-validation/${type}`;
    return axios
      .get(uri, { params })
      .then(response => {
        return response.data.valid;
      })
      .catch(error => {
        return false;
      });
  }
};

export default {
  namespaced: true,
  actions
};
