<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import Vue from 'vue';
import {get} from '../../base/util';
import {registerProductImpressionTracker} from '../../base/analytics/track-product-impression';
import trackProductClick from '../../base/analytics/track-product-click';

function createStaticCard({ el, list, position }) {
  let name = el.querySelector('.card__title');
  let link = el.querySelector('.card__link');

  let product = {
    name: get(name, 'innerText'),
    id: get(el, 'dataset.sku'),
    url: get(link, 'href'),
    price: get(el, 'dataset.price')
  }

  function productLinkClicked(evt) {
    evt.preventDefault();
    trackProductClick({
      product, 
      list, 
      position
    }, () => {
      document.location = product.url;
    });
  }


  registerProductImpressionTracker({ element: el, list, position, product });

  if (link) {
    link.addEventListener('click', productLinkClicked);
  }
}

export default {
  name: 'card-collection-hero',

  /**
   * So this component is attached to server side rendered markup
   * of a product grid. So we don't have a javascript 
   */
  mounted() {
    let products = this.$el.querySelectorAll('.product-card');
    let list = get(this.$el.querySelector('.hero__title'), 'innerText');

    for (let i = 0; i < products.length; i++) {
      let el = products[i];
      createStaticCard({ el, list, position: i+1 });
    }
  }
}
</script>