
import axios from 'axios';
import {debounce} from '../../base/util';

/**
 * support
 * this vuex module handles the support logic.
 */

const namespaced = true;

export const NAMESPACE = 'support';

export const MUTATION_TYPES = {
  setPageId: 'setPageId',
  setFlat: 'setFlat',
  operationStarted: 'operationStarted',
  operationEnded: 'operationEnded',
  setMetaSettings: 'setMetaSettings',
  updateMetaSettings: 'updateMetaSettings',
  setActiveFilters: 'setActiveFilters',
  setTotalResults: 'setTotalResults',
  clearActiveFilters: 'clearActiveFilters',
  setSupportGroups: 'setSupportGroups',
  setFlatSupportItems: 'setFlatSupportItems',
  toggleItem: 'toggleItem',
  setQuery: 'setQuery',
  clearItemToOpen: 'clearItemToOpen'
};

export const ACTION_TYPES = {
  init: 'init',
  fetchResult: 'fetchResult',
  setActiveFilters: 'setActiveFilters',
  setQuery: 'setQuery',
  clearActiveFilters: 'clearActiveFilters'
};

export const GETTER_TYPES = {

};

// apply overrides if exists.
let overrides = typeof(apiOverrides) !== 'undefined' ? apiOverrides : {};
let { SUPPORT, SUPPORT_FLAT } = overrides;

const state = {
  pageId: null,
  operationsRunning: 0,
  totalResults: 0,
  supportItems: [],
  query: null,
  flat: false,
  metaSettings: {
    recentQueries: [],
    tagGroups: []
  },
  itemToOpen: {
    groupId: '',
    itemId: ''
  }
};

const mutations = {
  [MUTATION_TYPES.setPageId](state, pageId) {
    state.pageId = pageId;
  },

  [MUTATION_TYPES.setFlat](state, flat) {
    if (flat && SUPPORT_FLAT) { state.supportEndpoint = SUPPORT_FLAT; }
    state.flat = flat;
  },

  [MUTATION_TYPES.operationStarted](state) {
    state.operationsRunning++;
  },

  [MUTATION_TYPES.operationEnded](state) {
    state.operationsRunning--;
  },

  [MUTATION_TYPES.setMetaSettings](state, { tagGroups, recentQueries }) {
    tagGroups.forEach(group => group.activeOptions = []);
    state.metaSettings.tagGroups = tagGroups;
    state.metaSettings.recentQueries = recentQueries;
  },

  [MUTATION_TYPES.updateMetaSettings](state, { tagGroups }) {
    state.metaSettings.tagGroups.forEach(tagGroup => {
      let serverTagGroup = tagGroups.filter((x) => x.id === tagGroup.id)[0];
      if (!serverTagGroup) return;
      serverTagGroup.options.forEach(serverOption => {
        let currentOption = tagGroup.options.filter(o => o.id == serverOption.id)[0];
        if (!currentOption) return;
        currentOption.numberOfHits = serverOption.numberOfHits;
      });
    });
  },

  [MUTATION_TYPES.setActiveFilters](state, { id, activeOptions }) {
    state.metaSettings.tagGroups
      .filter(group => group.id == id)
      .forEach(group => group.activeOptions = activeOptions);
  },

  [MUTATION_TYPES.setTotalResults](state, totalResults) {
    state.totalResults = totalResults;
  },

  [MUTATION_TYPES.clearActiveFilters](state) {
    state.metaSettings.tagGroups.forEach(group => group.activeOptions = []);
  },

  [MUTATION_TYPES.setSupportGroups](state, supportItems) {
    // just add a open flag to all items.
    [].concat.apply([], supportItems.map(group => group.items))
      .forEach(item => item.open = false);
    state.supportItems = supportItems;
  },

  [MUTATION_TYPES.setFlatSupportItems](state, supportItems) {
    supportItems.forEach(item => item.open = false);
    state.supportItems = supportItems;
  },

  [MUTATION_TYPES.toggleItem](state, { groupId, itemId }) {
    // we're currently loading results
    // so, it's not possible to activate the item before it's done.
    if (state.operationsRunning) {
      state.itemToOpen.groupId = groupId;
      state.itemToOpen.itemId = itemId;
      return;
    }

    if (state.flat) {
      for (let i = 0; i < state.supportItems.length; i++) {
        let item = state.supportItems[i];
        if (item.id === itemId) {
          item.open = !item.open;
          return;
        }
      }
    }


    for (let i = 0; i < state.supportItems.length; i++) {
      let group = state.supportItems[i];
      if (group.id !== groupId) { continue; }
      for (let j = 0; j < group.items.length; j++) {
        let item = state.supportItems[i].items[j];
        if (item.id === itemId) {
          item.open = !item.open;
          return;
        }
      }
    }
  },

  [MUTATION_TYPES.setQuery](state, query) {
    state.query = query;
  },

  [MUTATION_TYPES.clearItemToOpen](state) {
    state.itemToOpen.groupId = '';
    state.itemToOpen.itemId = '';
  }
};

const getters = {

  activeFiltersCount(state) {
    return state.metaSettings.tagGroups
      .map(group => group.activeOptions.length)
      .reduce((prev, count) => prev + count, 0);
  },

  totalGroupCount(state) {
    if (state.flat) { return state.totalResults; }
    return state.supportItems
      .map(group => group.numberOfHits)
      .reduce((prev, count) => prev + count, 0);
  }

};

let cancelSearch;
let fetchCancelToken;

/**
 * actions
 */

const actions = {

  [ACTION_TYPES.init]({dispatch}) {
    return dispatch('fetchResult', true);
  },

  [ACTION_TYPES.fetchResult]({commit, state}, init) {
    commit('operationStarted');

    if (fetchCancelToken) {
      fetchCancelToken();
      fetchCancelToken = null;
    }

    let params = {
      pageId: state.pageId,
      search: state.query,
      flat: state.flat
    };

    state.metaSettings.tagGroups.forEach(group => {
      if (group.activeOptions.length) {
        params[group.id] = group.activeOptions.join(',')
      }
    });

    let url = (state.flat && SUPPORT_FLAT) || SUPPORT || '/api/v1/support';
    return axios
      .get(url, {
        cancelToken: new axios.CancelToken(c => fetchCancelToken = c),
        params
      })
      .catch(error =>  {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      })
      .then(response => {
        if (!response) { return; }

        // if we're in a flat mode,
        // we can just assign the items and skip out of the meta stuff.
        if (state.flat) {
          commit(MUTATION_TYPES.setTotalResults, response.data.totalResults);
          commit(MUTATION_TYPES.setFlatSupportItems, response.data.result);
          return;
        }

        commit(MUTATION_TYPES.setSupportGroups, response.data.result);

        // if we're doing an init call, we can setup all meta information...
        if (init) {
          commit(MUTATION_TYPES.setMetaSettings, {
            tagGroups: response.data.tagGroups,
            recentQueries: response.data.popularSearches
          });
        }
        // ... else we just want to update the counts of the filters.
        else {
          commit(MUTATION_TYPES.updateMetaSettings, { tagGroups: response.data.tagGroups });
        }
      })

      .finally(() => {
        commit(MUTATION_TYPES.operationEnded);
        if (state.itemToOpen.groupId && state.itemToOpen.itemId) {
          commit(MUTATION_TYPES.toggleItem, state.itemToOpen);
          commit(MUTATION_TYPES.clearItemToOpen);
        }
        fetchCancelToken = null;
      });
  },

  [ACTION_TYPES.setActiveFilters]({dispatch, commit}, { id, activeOptions }) {
    commit('setActiveFilters', { id, activeOptions });
    dispatch('fetchResult');
  },

  [ACTION_TYPES.setQuery]({dispatch, commit}, query) {
    commit('setQuery', query);
    debouncedFetchSupportItems(dispatch);
  },

  [ACTION_TYPES.clearActiveFilters]({dispatch, commit}) {
    commit('clearActiveFilters');
    dispatch('fetchResult');
  }
};

// we just debounce a fetch support items, to use
// for instance when query is changing.
const debouncedFetchSupportItems = debounce((dispatch) => {
  dispatch('fetchResult');
}, 200);

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced
};
