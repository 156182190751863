
/**
 * Checks whether Google Tag Manager is available (If not, it 
 * is being blocked by ad-block or similar, or not loaded correctly) 
 * 
 * @return {Boolean} whether tag manager is available.
 */
export function isGoogleTagManagerAvailable() {
  let hasTagManagerLoaded = 
    typeof(google_tag_manager) !== 'undefined' && 
    typeof(dataLayer) !== 'undefined';

  if (!hasTagManagerLoaded) {
    console.warn('No google tag manager available');
  }

  return hasTagManagerLoaded;
}