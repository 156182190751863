import 'promise-polyfill/src/polyfill';
import Vue             from 'vue';
import i18n            from './i18n';
import store           from './store';
import * as components from './components';
import * as elements   from './elements';
import lazysizes       from 'lazysizes';
import svg4everybody   from 'svg4everybody';
import VueRouter       from 'vue-router';
import PageModal       from './components/page-modal/page-modal.vue';
import {
  ACTION_TYPES as messagesActions,
  NAMESPACE as messagesNamespace 
} from './store/modules/messages';

//Vue.config.devtools = true;

let mountPoint = document.getElementById('softube');
let pageId = document.querySelector('meta[name=page-id]');
let adminMode = mountPoint.classList.contains('page--admin-mode');
let mode = adminMode 
  ? 'abstract'
  : 'history';

const router = new VueRouter({mode});

/**
 * Create our root instance Vue object.
 */
const app = new Vue({
  el: '#softube',
  store,
  router,
  i18n,
  components: {
    ...components,
    ...elements
  },
  mounted() {
    lazysizes.init();
    svg4everybody();

    // Create the page modal.
    let PageModalComponent = Vue.extend(PageModal);
    let pageModal = new PageModalComponent({ parent: this });
    pageModal.$mount();
    this.$el.appendChild(pageModal.$el);

    // If we coming from an old link with hash, we can use that to navigate
    // with history
    if (mode === 'history' && /#\/.+/.test(window.location.hash)) {
      this.$nextTick(() => {
        this.$router.replace({ path: window.location.hash.substring(1) });
      });
    }

    if (typeof(SOF_I18N) !== 'undefined') {
      i18n.mergeLocaleMessage(document.documentElement.lang, SOF_I18N);
    }
  },
  created () {
    if (!this.$store) { return; }
    this.$store.dispatch('shoppingCart/fetchShoppingCart', { getForCheckout: mountPoint.classList.contains('page--checkout') });
    this.$store.dispatch(`${messagesNamespace}/${messagesActions.fetchContextMessages}`, { 
      pageId: pageId.content,
      adminMode
    });
  }
});

export default app;
