import ScrollMagic from 'scrollmagic';

let controller;

export function getScrollController() {
  if (!controller) {
    controller = new ScrollMagic.Controller();
  }
  return controller;
}
