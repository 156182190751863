import {get} from '../util';
let currencyMeta = document.querySelector('meta[property="softube:currency"]');

/**
 * Tracks that a product was added to the cart in the
 * enhanced ecom analytics.
 * @see https://developers.google.com/tag-manager/enhanced-ecommerce#add
 * @param {Object} product the product that was added
 */

export function trackAddToCart({ product, quantity = 1 }) {
  if (typeof(dataLayer) === 'undefined') { return; }
  if (!product) { return; }
  dataLayer.push({
    'event': 'addToCart',
    'ecommerce': {
      'currencyCode': get(currencyMeta, 'content'),
      'add': {
        'products': [{
          'name': product.name,
          'id': product.id,
          'price': product.price,
          'quantity': quantity
         }]
      }
    }
  });
}

export function trackRemoveFromCart({ product, quantity = 1 }) {
  if (typeof(dataLayer) === 'undefined') { return; }
  if (!product) { return; }
  dataLayer.push({
    'event': 'removeFromCart',
    'ecommerce': {
      'currencyCode': get(currencyMeta, 'content'),
      'remove': {                               // 'remove' actionFieldObject measures.
        'products': [{                          //  removing a product to a shopping cart.
            'name': product.name,
            'id': product.id,
            'price': product.price,
            'quantity': quantity
        }]
      }
    }
  });
}
