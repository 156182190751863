
import {setCookie, getCookie} from 'tiny-cookie';

/**
 * @module cookie-consent
 *
 */

const key = 'sui-cookie-consent';

const state = {
  ...getCookie(key, JSON.parse) || { dismissed: false, acceptAll: false }
};

const mutations = {
  acceptAll(state) {
    state.dismissed = true;
    state.acceptAll = true;
    setCookie(key, state, JSON.stringify, {expires: '1Y'});
    location.href = getLocationWithoutForceCookieModalQueryString(location.href);
  },

  onlyRequired(state) {
    state.dismissed = true;
    state.acceptAll = false;
    setCookie(key, state, JSON.stringify, {expires: '1Y'});
    location.href = getLocationWithoutForceCookieModalQueryString(location.href);
  }
};

/* This is needed to remove the query string used to trigger the cookie modal. 
The query string, if used, needs to be removed not to trigger opening the modal again on page reload after modal button clicked */
function getLocationWithoutForceCookieModalQueryString(href) {
  return href.replace('opencookiemodal=true', '').replace('&&', '&').replace('?&', '?').replace(/\?\s*$/, '');
}

export default {
  state,
  mutations,
  namespaced: true
};
