import axios from 'axios';
import {get} from '../../base/util';
import {trackAddToCart, trackRemoveFromCart} from '../../base/analytics/track-cart';
import {debounce} from '../../base/util';
import {
  NAMESPACE as nsMessages,
  ACTION_TYPES as atMessages
} from './messages';

/**
 * @module shopping-cart
 * this Vuex module handles the state
 * of the cart.
 */

// apply overrides if exists.
const overrides = typeof (apiOverrides) !== 'undefined' ? apiOverrides : {};
const { CART, CART_META, CART_ADD, CART_PROMOTION_ADD, CART_PROMOTION_REMOVE, CART_REMOVE } = overrides;

// this controls whether we want to make sure the loading state
// is visible even though it finishes faster than <delayTime>
const delayLoader = false;
const delayTime = 1500;

function getProductTrackingData(row) {
  let data = {
    id: get(row, 'productId'),
    name: get(row, 'name')
  };

  let price = get(row, 'price.campaignDecimal') || get(row, 'price.normalDecimal');
  if (typeof(price) === 'number') {
    data.price = price.toFixed(2).toString();
  };
  return data;
}

export const NAMESPACE = 'shoppingCart';
export const ACTION_TYPES = {
  addItem: 'addItem',
  updateItemQuantity: 'updateItemQuantity',
  removeItem: 'removeItem',
  fetchShoppingCart: 'fetchShoppingCart'
};
export const GETTERS = {
  totalItemsInCart: 'totalItemsInCart'
};

const cancellationTokens = {};

const state = {
  addingPromotion: false,
  loading: false,
  items: [],
  includesHardware: false,
  hasItemThatIncludesLegacyPlugins: false,
  allowDifferentDeliveryAddress: false,
  totalWithVAT: '',
  totalWithVatDecimal: 0,
  discountWithoutVAT: '',
  discountWithVAT: '',
  totalVAT: '',
  totalVatDecimal: 0,
  orderValueWithVAT: '',
  deliveryCostWithVAT: '',
  deliveryDescription: '',
  subtotalWithoutVAT: '',
  promotionalCode: '',
  newPromotionalCode: '',
  promotionalCodeErrorMessage: '',
  isBusinessUser: '',
  isSoftubeIncOrder: false,
  currencyCode: '',
  paymentNonce: '',
  paymentProviderName: '',
  cartMetaErrors: [],
  userIpAddress: '',
}

const getters = {
  count: state => state.items.length,
  hasItems: state => !!state.items.length,
  hasPromotionalCode: state => !!state.promotionalCode,
  isInCart: (state) => (id) => !!state.items.filter(i => i.productId === id)[0],
  rowQuantity: (state) => (rowId) => {
    var row = state.items.filter(r => r.rowId === rowId)[0];
    if (!row) { return 0; }
    return row.quantity;
  },
  totalItemsInCart: (state) => {
    return state.items
      .map(i => i.quantity)
      .reduce((prev, curr) => prev + curr, 0)
  },
  cartMetaErrors: (state) => {
    return state.cartMetaErrors;
  }
}

const mutations = {

  /**
   * Sets the current cart in Vuex to the passed cart object.
   * 
   * @param {Object} state Vuex State
   * @param {Object} cart Cart
   */
  setCart(state, cart) {
    cart.items.forEach(item => item.quantityChanging = false);
    state.items = cart.items;
    state.includesHardware = cart.includesHardware;
    state.hasItemThatIncludesLegacyPlugins = cart.hasItemThatIncludesLegacyPlugins;
    state.allowDifferentDeliveryAddress = cart.allowDifferentDeliveryAddress;
    state.totalWithVAT = cart.totalWithVAT;
    state.totalVAT = cart.totalVAT;
    state.totalVatDecimal = cart.totalVatDecimal;
    state.discountWithoutVAT = cart.discountWithoutVAT;
    state.discountWithVAT = cart.discountWithVAT;
    state.subtotalWithoutVAT = cart.subtotalWithoutVAT;
    state.promotionalCode = cart.promotionalCode;
    state.isBusinessUser = cart.isBusinessUser;
    state.currencyCode = cart.currencyCode;
    state.totalWithVatDecimal = cart.totalWithVatDecimal;
    state.orderValueWithVAT = cart.orderValueWithVAT;
    state.deliveryCostWithVAT = cart.deliveryCostWithVAT;
    state.deliveryDescription = cart.deliveryDescription;
    state.isSoftubeIncOrder = cart.isSoftubeIncOrder;
    state.userIpAddress = cart.userIpAddress;
    if (cart.paymentNonce) {
      state.paymentNonce = cart.paymentNonce;
    }
    state.paymentProviderName = cart.paymentProviderName;
  },
  setCartMetaErrors(state, cartMetaErrors) {
    state.cartMetaErrors = cartMetaErrors;
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },

  setRowQuantity(state, { rowId, quantity }) {
    var row = state.items.filter(r => r.rowId === rowId)[0];
    if (row) {
      row.quantity = quantity;
    }
  },

  setNewPromotionalCode(state, promotionalCode) {
    state.newPromotionalCode = promotionalCode;
  },

  setAddingPromotion(state, adding) {
    state.addingPromotion = adding;
  },

  setPromotionalCodeError(state, message) {
    state.promotionalCodeErrorMessage = message;
  },

  addPendingItem(state, productId) {
    state.items.push({ productId, pending: true });
  },

  removeItem(state, productId) {
    let index = state.items.findIndex(p => p.productId === productId);
    if (index < 0) { return; }
    state.items.splice(index, 1);
  },

  setQuantityChanging(state, { rowId, changing }) {
    let row = state.items.find(item => item.rowId === rowId);
    if (!row) { return; }
    row.quantityChanging = changing;
  }
};

/**
 * 
 */
const debouncedUpdateItemQuantity = debounce(({ commit, dispatch, rowId, quantity, oldQuantity }) => {
  commit('setQuantityChanging', { rowId, changing: true });
  axios
    .put(`/api/v1/cart/items/${rowId}/${quantity}`)
    .then(response => {
      commit('setCart', response.data);
    })
    .catch(error => {
      if (error.response.data.errorCode === 106) {
        // Not enough stock error
        // Reload cart with updated quantities
        let endpoint = CART || '/api/v1/cart';
        let params = { getForCheckout: true }

        commit('setLoading', true); 
    
        axios
          .get(endpoint, { params })
          .then(response => {
            let newCart = response.data;
            if (newCart) {
              commit('setCart', newCart);
            }    
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => {
            commit('setLoading', false);
          });         
      } else {
        commit('setRowQuantity', { rowId, quantity: oldQuantity });
      }

      dispatch(`${nsMessages}/${atMessages.showMessage}`, {
        icon: 'info',
        text: error.response.data.message,
        type: 'callout'
      }, { root: true });
    })
    .finally(() => {
      commit('setQuantityChanging', { rowId, changing: false });
    });
}, 500);

const actions = {
  /**
   * Fetches the current shopping cart via the Api.
   * @param {Object} param0 vuex context
   * @param {Object} options options.
   */
  fetchShoppingCart({ commit }, options = {}) {
    let endpoint = CART || '/api/v1/cart';
    let getForCheckout = !!options.getForCheckout;

    commit('setLoading', true);
    let start = Date.now();

    let params = {}
    if (getForCheckout) {
      params.getForCheckout = true;
    }

    return axios
      .get(endpoint, { params })
      .then(response => {
        let newCart = response.data;
        let pricesChanged = state.totalWithVAT !== newCart.totalWithVAT;

        let prevQty = state.items.reduce(function(prev, cur) { return prev + cur.quantity; }, 0);
        let newQty = newCart.items.reduce(function(prev, cur) { return prev + cur.quantity; }, 0);
        let productsRemoved = prevQty > newQty;

        if (newCart) {
          commit('setCart', newCart);
        }

        let end = Date.now();
        let diff = end - start;

        if (delayLoader && diff < delayTime) {
          return new Promise(resolve => {
            setTimeout(resolve({ pricesChanged: pricesChanged, productsRemoved: productsRemoved }), delayTime - diff);
          });
        } else {
          return({ pricesChanged: pricesChanged, productsRemoved: productsRemoved });
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },

  /**
   * Makes a XHR call to the cart api and adds and
   * tried to add the provided productId to the cart.
   * 
   * @param {Object} context
   * @param {Object} payload
   * @return {Promise} a promise for completion
   */
  addItem({commit, dispatch}, { productId, productType = 'product'}) {
    commit('addPendingItem', productId);
    let messageId;

    // dependind on what type of product is being added...
    // we want to display different message types, right now 
    // we only have two types.
    let messageType = productType === 'product'

    switch (productType) {
      case 'upgrade':
        messageType = 'secondary';
        break;
      default:
        messageType = 'success';
        break;
    }

    // kick off a message that shows that we're adding
    // products to the cart...
    return dispatch(`${nsMessages}/${atMessages.showMessage}`, {
      loading: true,
      type: messageType,
      removeable: false
    }, { root: true })

      // ...when we've got an id, initiate the post to the api.
      .then(id => {
        let endpoint = CART_ADD || `/api/v1/cart/items/${productId}`;
        messageId = id;
        return axios.post(endpoint);
      })

      // ... if we've had a successful add.
      .then(response => {
        let cart = get(response, 'data.cart');
        let text = get(response, 'data.message');
        let cta = get(response, 'data.cta');
        let myProductsUrl = get(response, 'data.data.myProductsUrl');

        // if this was a free license deposit:
        if (myProductsUrl) {
            commit('removeItem', productId);

            let message = {
                loading: false,
                icon: 'open-box',
                text,
                timeToLive: 5000,
                removeable: true,
                cta: {
                    text: 'My Products',
                    href: myProductsUrl
                }
            };

            return dispatch(`${nsMessages}/${atMessages.updateMessage}`, {
              id: messageId,
              message
            }, {
              root: true
            });
        }

        // if this happens, the api is malfunctioning.
        // we can't do much other than throwing.
        if (!cart) { throw new Error('An error occured with your shopping cart'); }

        commit('setCart', cart);

        let message = {
          loading: false,
          icon: 'cart',
          text,
          cta,
          timeToLive: 5000,
          removeable: true,
        };

        // find the product that was added now when we have all the info.
        let row = cart.items.find(item => item.productId === productId);
        trackAddToCart({ product: getProductTrackingData(row) });

        return dispatch(`${nsMessages}/${atMessages.updateMessage}`, {
          id: messageId,
          message
        }, {
          root: true
        });
      })

      .catch(error => {
        commit('removeItem', productId);
        let text = get(error, 'response.data.message') || get(error, 'message');
        let type = get(error, 'response.data.type') || get(error, 'type') || 'callout';

        let message = {
            text,
            loading: false,
            icon: 'info',
            type: type,
            timeToLive: 10000,
            removeable: true,
          };

        return dispatch(`${nsMessages}/${atMessages.updateMessage}`, {
          id: messageId,
          message
        }, {
          root: true
        });
      });
  },

  addPromotionalCode({ dispatch, commit }, promotionalCode) {
    commit('setAddingPromotion', true);
    commit('setPromotionalCodeError', null);
    let msgId;

    return dispatch(`${nsMessages}/${atMessages.showMessage}`, {
      loading: true,
      type: 'success',
      removeable: true
    }, { root: true })

      .then(id => {
        if (promotionalCode)
          promotionalCode = promotionalCode.normalize('NFKD');
        let endpoint = CART_PROMOTION_ADD || `/api/v1/cart/promotions/${promotionalCode}`;
        msgId = id;
        return axios.post(endpoint);
      })

      .then(response => {
        let cart = get(response, 'data.cart');
        let text = get(response, 'data.message');

        if (!cart) { throw new Error('An error occurred with your shopping cart'); }
        commit('setCart', cart);
        commit('setNewPromotionalCode', '');

        let message = {
          text,
          loading: false,
          icon: 'promo',
          timeToLive: 5000,
          removeable: false
        };

        dispatch(`${nsMessages}/${atMessages.updateMessage}`, {
          id: msgId,
          message
        }, {
          root: true
        });
      })

      .catch(error => {
        let text = get(error, 'response.data.message') || error.message;
        let message = {
          text,
          loading: false,
          icon: 'info',
          type: 'callout',
          timeToLive: 5000,
          removeable: false,
          notinteractive: true
        };

        dispatch(`${nsMessages}/${atMessages.updateMessage}`, {
          id: msgId,
          message
        }, {
          root: true
        });

        throw error;
      })

      .finally(() => {
        commit('setAddingPromotion', false);
      });
  },

  /**
   * Removes the currently applied promotional code from 
   * the cart.
   * 
   * @param {Object} param0 vuex context
   */
  removePromotionalCode({ dispatch, commit }) {
    let id;
    // Start off by showing an loading message.
    return dispatch('messages/showMessage', { loading: true, type: 'success' }, { root: true })

      // When that is added, fire off the delete-call...
      .then(msgId => {
        id = msgId;
        let endpoint = CART_PROMOTION_REMOVE || '/api/v1/cart/promotions';
        return axios.delete(endpoint);
      })

      // ...when response was received.
      .then(response => {
        let cart = get(response, 'data.cart');
        let text = get(response, 'data.message');

        if (!cart) { throw new Error('An error occured while removing your promotional code'); }
        commit('setCart', cart);
        commit('setNewPromotionalCode', '');

        let message = {
          text: text,
          loading: false,
          icon: 'promo',
          timeToLive: 5000,
          removeable: false
        };

        return dispatch('messages/updateMessage', { id, message }, { root: true })
      })

      // any errors recieved
      .catch(error => {
        let text = get(error, 'response.data.message') || error.message;
        let message = {
          text: text,
          loading: false,
          icon: 'promo',
          timeToLive: 5000,
          removeable: false,
          type: 'callout'
        };

        return dispatch('message/updateMessage', { id, message }, { root: true });
      });
  },

  /**
   * Updates the quantity of a row in the cart.
   * @param {Object} context
   * @param {Object} payload 
   * @return {Promise} A promise for completion.
   */
  updateItemQuantity({ commit, dispatch, state }, { rowId, quantity }) {
    // Ecom-tracking
    let row = state.items.find(item => item.rowId === rowId);
    let diff = quantity - row.quantity;
    if (diff > 0) {
      trackAddToCart({ product: getProductTrackingData(row), quantity: diff });
    } else if (diff !== 0) {
      trackRemoveFromCart({ product: getProductTrackingData(row), quantity: Math.abs(diff) });
    }

    // keep track of the old quantity, if the cart update 
    // should fail remotely, we want to be able to revert to the old 
    // quantity
    let oldQuantity = row.quantity;
    commit('setRowQuantity', { rowId, quantity });

    debouncedUpdateItemQuantity({ commit, dispatch, rowId, quantity, oldQuantity });
  },

  removeItem({ commit, state }, { rowId }) {
    let endpoint = CART_REMOVE || `/api/v1/cart/items/${rowId}`;
    
    return axios
      .delete(endpoint)
      .then(response => {
        // Ecom-tracking.
        let row = state.items.find(item => item.rowId === rowId);
        trackRemoveFromCart({ product: getProductTrackingData(row), quantity: row.quantity });
        commit('setCart', response.data);
      })
      .catch(error => {
        return Promise.reject();
      });
  },

  /**
   * Updates meta data on the cart itself, specifically things
   * like VAT-number and country code. 
   * 
   * *Note:* this is only applicable for anonymous users since
   * logged in users use their account settings.
   * 
   * @param {Object} param0 vuex context
   * @param {Object} metaData the metadata
   * @returns Promise for Cart
   */
  updateCartMeta({ commit, dispatch, state }, metaData) {
    let endpoint = CART_META || '/api/v1/cart';

    // first check if a request to update is already running, if 
    // so; cancel that request and let this run instead.
    let cancel = cancellationTokens[endpoint];
    if (cancel) {
      cancel();
    }

    return axios
      .patch(endpoint, metaData, {
        cancelToken: new axios.CancelToken((c) => {
          cancellationTokens[endpoint] = c;
        })
      })
      .then(response => {
        let newCart = response.data;
        let pricesChanged = state.totalWithVAT !== newCart.totalWithVAT;

        let prevQty = state.items.reduce(function(prev, cur) { return prev + cur.quantity; }, 0);
        let newQty = newCart.items.reduce(function(prev, cur) { return prev + cur.quantity; }, 0);
        let productsRemoved = prevQty > newQty;

        // TODO: add tracking.
        commit('setCart', newCart);
        commit('setCartMetaErrors', []);
        return { pricesChanged: pricesChanged, productsRemoved: productsRemoved };
      })
      .catch(error => {
        if (error.response.status === 400) {
          commit('setCartMetaErrors', error.response.data.errors);

          let generalAddressError = error.response.data.errors.find(err => err.fieldName == 'GeneralAddress');
          if (generalAddressError && generalAddressError.errorMessages) {
            dispatch(`${nsMessages}/${atMessages.showMessage}`, {
              icon: 'info',
              text: generalAddressError.errorMessages[0],
              type: 'callout'
            }, { root: true });
          }
        }
        if (!axios.isCancel(error)) {
          console.error(error);
        }
        throw error;
      })

      // ...delete the cancellation token when done...
      .finally(() => {
        delete cancellationTokens[endpoint];
      })
  }
};

export default {
  actions,
  state,
  mutations,
  getters,
  namespaced: true
};
