
/**
 * This function can be used to throw unhandled exception at,
 * depending on environment the error is reported.
 * 
 * If all errors is thrown to this function it will be easy to 
 * log them all the same way.
 * 
 * @param {Error} error the error recieved
 */
export function unhandledError(error) {
  if (!console) { return; }
  console.error(error);
}

/**
 * This mixin can be used to add a consistent way of showing errors.
 */
export const flashMessageErrorNotifier = {
  methods: {
    showFlashMessageError(text, cta) {
      this.$store.dispatch('messages/showMessage', {
        icon: 'info',
        type: 'callout',
        text,
        cta
      });
    }
  }
};