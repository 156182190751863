<template>
  <div id="payment" class="payment sui-cover" :class="{ 'sui-cover--active': !paymentAvailable, 'sui-cover--disable': !paymentAvailable }">
    <h2 class="checkout__sub-title payment__title">{{ $t('checkout.payment.heading') }}</h2>
    <div class="payment__content" v-show="payWithBraintree">
      <!-- While braintree is loading show a loader  -->
      <div v-show="braintreeLoaded" ref="dropinContainer" class="payment__braintree-dropin"></div>
      <loader-bars v-if="!braintreeLoaded && !braintreeFailed" />
      <div>
        <a :href="braintreeUrl" target="_blank" rel="noopener">
          <img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png" width="280px" height="44px" border="0" />
        </a>
      </div>
      <div class="payment__newsletter" v-if="$t('checkout.newsletterText')">
        <div class="editorial" v-if="$t('checkout.newsletterPromoText')" v-html="$t('checkout.newsletterPromoText')"></div>
        <div class="checkbox checkbox--small">
          <input id="subscribeNewsletter" type="checkbox" v-model="account.edit.subscribeNewsletter" />
          <label for="subscribeNewsletter" class="checkbox__lbl">{{$t('checkout.newsletterText')}}</label>
        </div>
      </div>
      <button @click="makePurchase" class="payment__confirm btn btn--huge g-recaptcha" :disabled="!paymentMethodRequestable || purchaseInProgress">
        {{ $t('checkout.payment.confirmButton', { priceToPay }) }}
      </button>
      <div class="editorial payment__terms" v-html="$t('checkout.payment.terms')"></div>
    </div>
    <div class="payment__content" v-if="payWithStripe">
      <stripe :stripePublishableKey="stripePublishableKey" :recaptchaSiteSecret="recaptchaSiteSecret" />
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import LoaderBars from '../../../elements/loader/loader-bars.vue';
  import Stripe from './stripe/stripe.vue';
  import {
    trackCheckout,
    trackCheckoutOptions
  } from '../../../base/analytics/track-checkout.js';

  const PAYMENT_TOO_LONG_TIMEOUT = 3000;

  /**
   * @component payment
   * this component is responsible for payment, ie. the braintree integration.
   */

  export default {
    name: 'payment',

    components: {
      LoaderBars,
      Stripe
    },

    props: {
      braintreeMerchantId: { type: String },
      stripePublishableKey: { type: String },
      recaptchaSiteSecret: { type: String }
    },

    data() {
      return {
        paymentTracked: false
      };
    },

    computed: {
      braintreeUrl() {
        return `https://www.braintreegateway.com/merchants/${this.braintreeMerchantId}/verified`;
      },
      recaptchaClientSecret() { return this.recaptchaSiteSecret; },
      payWithBraintree() { return this.paymentProviderName === 'Braintree' },
      payWithStripe() { return this.paymentProviderName === 'Stripe' },

      ...mapState({
        priceToPay: state => state.shoppingCart.totalWithVAT,
        paymentNonce: state => state.shoppingCart.paymentNonce,
        paymentProviderName: state => state.shoppingCart.paymentProviderName,
        currencyCode: state => state.shoppingCart.currencyCode,
        paymentMethodRequestable: state => state.checkout.paymentMethodRequestable,
        braintreeLoaded: state => state.checkout.braintreeLoaded,
        braintreeFailed: state => state.checkout.braintreeFailed,
        purchaseInProgress: state => state.checkout.purchaseInProgress,
        paymentOptionSelected: state => state.checkout.paymentOptionSelected,
        shoppingCart: state => state.shoppingCart,
        account: state => state.account,
        recaptchaIsOK: state => state.recaptchaIsOK
        // recaptchaChallenge: state => state.checkout.recaptchaChallenge
      }),

      ...mapGetters({
        paymentAvailable: 'checkout/paymentAvailable',
        recaptchaChallenge: 'checkout/recaptchaChallenge'
      })
    },

    mounted() {
      if (this.paymentNonce && this.payWithBraintree) {
        this.createBraintreeDropin();
      }
    },

    watch: {
      /**
       * Whenever payment nonce has changed
       * we use that.
       */
      paymentNonce() {
        if (this.payWithBraintree) {
          this.createBraintreeDropin();
        }
      },

      paymentOptionSelected(paymentOption) {
        if (!paymentOption) { return; }
        if (!this.paymentTracked) {
          trackCheckout(this.shoppingCart, 2, paymentOption);
          this.paymentTracked = true;
          return;
        }
        trackCheckoutOptions(2, paymentOption);
      }
    },

    methods: {
      ...mapMutations({ 
        setRecaptchaChallenge: 'checkout/setRecaptchaChallenge',
        setUpdatingAccount: 'checkout/setUpdatingAccount',
        setEditingGuestDetails: 'checkout/setEditingGuestDetails',
      }),

      createBraintreeDropin() {
        this.$store.dispatch('checkout/createBraintree', {
          authorization: this.paymentNonce,
          container: this.$refs.dropinContainer
        })
          .catch(error => {
            console.error(error);
            this.$store.dispatch('messages/showMessage', {
              icon: 'info',
              text: 'An error occurred while loading payment plugin.',
              type: 'callout',
              removeable: false,
              timeToLive: 0,
              cta: {
                text: 'Reload page',
                href: location.href
              }
            });
          });
      },

      /**
       * Initiates the purchase in the store.
       */
      makePurchase() {
        // we keep track of when a purchase started,
        // if it takes too long time, we notify the user
        // that we're working.
        let modalShown = false;
        let paymentStarted = Date.now();
        let slowChecker = setInterval(() => {
          if (Date.now() - paymentStarted > PAYMENT_TOO_LONG_TIMEOUT) {
            modalShown = true;
            this.$store.commit('modals/showModal', {
              id: 'checkout',
              closeable: false,
              dismissedPermanently: false,
              type: 'checkout',
              light: true,
              content: {
                statusText: this.$t('checkout.payment.inProgressStatusText')
              }
            });
            clearInterval(slowChecker);
          }
        }, 150);

        // console.log("makePurchase with " + this.recaptchaSiteSecret);
        grecaptcha.ready(() => {
          grecaptcha.execute(this.recaptchaSiteSecret, { action: 'submit' }).then((token) => {
            // Add your logic to submit to your backend server here.
            this.$store.commit('checkout/setRecaptchaChallenge', token);

            // Initiate the purchase.
            this.$store
              .dispatch('checkout/makePurchase')

              // When successfull, kill the interval.
              .then(({ confirmationPageUrl }) => {
                clearInterval(slowChecker);
                if (!confirmationPageUrl) { throw new Error('No confirmation page'); }

                // ... and then redirect to order confirmation page...
                window.location = confirmationPageUrl;
              })

              // If error, show an error message.
              .catch(error => {
                clearInterval(slowChecker);

                if (this.account.signedIn) {
                    this.setUpdatingAccount(true);
                } else {
                    this.setEditingGuestDetails(true);
                }

                if (error && error.type === 'CheckoutError') {
                  // If we've recieved an error, we reload the cart
                  this.$store.dispatch('shoppingCart/fetchShoppingCart', { getForCheckout: true });
                }

                let errorText = this.$t('checkout.payment.generalErrorMessage');
                let cta = null;
                if (error.response.data && error.response.data.errorCode === 106) {
                  errorText = error.response.data.message;
                  cta = { text: this.$t('checkout.purchase.guestPanel.cartChange.cta'), href: '#shopping-cart' }
                }
                this.$store.commit('modals/dismissModal');
                this.$store.dispatch('messages/showMessage', {
                  icon: 'info',
                  text: errorText,
                  type: 'callout',
                  removeable: true,
                  timeToLive: 0,
                  cta: cta
                });
              });
          }, (error) => {
            // console.log("ReCAPTHCA failed: " + error);
            // console.log("KEY: " + this.recaptchaSiteSecret);
          });
        }, (error) => {
          console.log("grecaptcha.ready failed: " + error);
        });
      }
    }
  }
</script>