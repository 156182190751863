import { get, pick } from '../../base/util';
import asciiFolder from '../../base/ascii-folder';

// load potential overrides.
let overrides = typeof (apiOverrides) !== 'undefined' ? apiOverrides : {};
let { CART_CHECKOUT } = overrides;

let braintreeDropin;

/**
 * @class LiabilityNotShiftenError
 * Custom error to identify Liability Errors.
 */
export class LiabilityNotShiftedError extends Error {
  constructor(message) {
    super(message);
  }
}

export class CheckoutError extends Error {
  constructor(message, response) {
    super(message);
    this.response = response;
    this.type = 'CheckoutError';
  }
}

const defaultBraintreeOptions = {
  paymentOptionPriority: ['paypal', 'card'],
  paypal: {
     flow: 'checkout'
  }
}

/**
 * State
 */

const state = {
  purchaseMode: '',
  purchaseInProgress: false,
  updatingAccount: false,
  braintreeLoaded: false,
  braintreeValid: false,
  braintreeFailed: false,
  braintreeClientToken: '',
  paymentMethodRequestable: false,
  paymentOptionSelected: '',
  recaptchaIsOK: false,
  recaptchaChallenge: '',
  editingGuestDetails: true,
  customerDetailsFormDisabled: false,
};

/**
 * Getters
 */

const getters = {
  /**
   * Returns whether the payment section should 
   * be enabled and available or not.
   */
  paymentAvailable: (state, getters, rootState) => {
    if (state.braintreeFailed) { return false; }
    if (!state.purchaseMode) { return false; }
    let lookupObjectKey = state.purchaseMode === 'guest' 
      ? 'edit'
      : 'current';

    return rootState.account[lookupObjectKey].canPurchase;
  },

  /**
   * Returns whether the user is editing the eccount details.
   * He/she is forced to do so if he is flagged from backend that he needs 
   * to make updates.
   */
  editingAccountDetails: (state, getters, rootState) => {
    return rootState.account.signedIn && (!rootState.account.current.canPurchase || state.updatingAccount);
  },

  /**
   * Returns whether threeDSecure should be used or not.
   */
  useThreeDSecure: (state, getters, rootState) => {
    return true; //rootState.shoppingCart.currencyCode !== 'USD';
  },

  /**
   * Returns whether to use recaptcha or not
   */
  useRecaptcha: (state, getters, rootState) => {
    return true; // for now
  },

  getRecaptchaChallenge: (state, getters, rootState) => {
    return state.recaptchaChallenge;
  }
};

const mutations = {
  /**
   * Updates whether braintree has finished loading 
   * or not.
   * 
   * @param {Object} state 
   * @param {Boolean} loaded 
   */
  setBraintreeLoaded(state, loaded) {
    state.braintreeLoaded = loaded;
  },

  /**
   * Sets whether the payment method is requestable in the state
   * 
   * @param {Object} state 
   * @param {Boolean} requestable 
   */
  setPaymentMethodRequestable(state, requestable) {
    state.paymentMethodRequestable = requestable;
  },

  /**
   * Sets the current purchase mode (which panel is selected.)
   * @param {Object} state current state
   * @param {String} newMode the new purchase mode.
   */
  setPurchaseMode(state, newMode) {
    state.purchaseMode = newMode;
  },

  /**
   * Sets whether the user is updating the current account.
   * @param {Object} state current state
   * @param {Boolean} updatingAccount the new value
   */
  setUpdatingAccount(state, updatingAccount) {
    state.updatingAccount = updatingAccount;
  },

  /**
   * Sets whether the purchase is in progress
   * @param {Object} state state
   * @param {Boolean} inProgress whether in progress or not
   */
  setPurchaseInProgress(state, inProgress) {
    state.purchaseInProgress = inProgress;
  },

  /**
   * Sets whether braintree failed loading.
   * @param {Object} state the current state
   * @param {Boolean} failed Whether it failed or not
   */
  setBraintreeFailed(state, failed) {
    state.braintreeFailed = failed;
  },

  /**
   * 
   * @param {Object} state Vuex state
   * @param {Object} merchants a key-value object with currency + merchant ID
   */
  setMerchants(state, merchants) {
    state.merchants = merchants;
  },

  setPaymentOptionSelected(state, paymentOption) {
    state.paymentOptionSelected = paymentOption;
  },

  /**
   * 
   * @param {Object} state Vuex state
   * @param {Object} challenge a string value of the Recaptcha challenge
   */
  setRecaptchaChallenge(state, challenge) {
    state.recaptchaChallenge = challenge;
  },

  setEditingGuestDetails(state, editing) {
    state.editingGuestDetails = editing;
  },

  setCustomerDetailsFormDisabled(state, disabled) {
    state.customerDetailsFormDisabled = disabled;
  }
}

let createPromise;

const actions = {
  /**
   * Creates a Braintree dropin instance.
   * 
   * @param {Context} param0 Vuex context
   * @param {Object} options the options to send when creating
   */
  createBraintree({ commit, getters, rootState }, options) {
    if (!createPromise) {
      createPromise = Promise.resolve();
    }

    // if we already have an instance when asked to create one, we first 
    // tear it down.
    return createPromise
      // if we need to tear down a previous, do that first.
      .then(() => {
        if (braintreeDropin) {
          return braintreeDropin.teardown();
        }
      })

      // ... Create a braintree dropin and wire some events.
      .then(() => {
        let braintreeOptions = Object.assign({}, defaultBraintreeOptions, options);

        braintreeOptions.paypal.amount = rootState.shoppingCart.totalWithVatDecimal.toFixed(2);
        braintreeOptions.paypal.currency = rootState.shoppingCart.currencyCode;

        if (getters.useThreeDSecure) {
          braintreeOptions.threeDSecure = true;
        }

        return braintree.dropin.create(braintreeOptions);
      })

      .then(dropinInstance => {
        braintreeDropin = dropinInstance;
        braintreeDropin.on('paymentMethodRequestable', () => {
          commit('setPaymentMethodRequestable', true);
        })
        braintreeDropin.on('noPaymentMethodRequestable', () => {
          commit('setPaymentMethodRequestable', false);
        });
        braintreeDropin.on('paymentOptionSelected', (evt) => {
          commit('setPaymentOptionSelected', evt.paymentOption);
        });

        commit('setBraintreeLoaded', true);
      })

      .catch(error => {
        console.error(error);
        commit('setBraintreeFailed', true);
        throw error;
      })

      .finally(() => {
        createPromise = null;
      });
  },

  /**
   * Makes a full purchase with braintree and checkout the 
   * cart.
   * @param {Object} param0 Vuex context.
   * @returns {Promise} a promise for completion. 
   */
  makePurchase({ commit, getters, rootState }) {
    let url = CART_CHECKOUT || '/api/v1/cart/checkout';
    let options;

    commit('setPurchaseInProgress', true);

    // if we should support 3D secure, we need to assign 
    // more fields
    if (getters.useThreeDSecure) {
      let account = rootState.account.signedIn 
        ? rootState.account.current 
        : rootState.account.edit;

      options = {
        threeDSecure: {
          collectDeviceData: true,
          amount: rootState.shoppingCart.totalWithVatDecimal.toFixed(2),
          email: account.email,
          mobilePhoneNumber: account.phoneMobile,
          billingAddress: {
            givenName: asciiFolder.foldReplacing(account.firstName),
            surname: asciiFolder.foldReplacing(account.lastName),
            streetAddress: account.address.substring(0, 49),
            locality: account.city,
            region: account.state,
            postalCode: account.postcode,
            countryCodeAlpha2: account.countryCode,
            phoneNumber: account.phoneMobile,
          },
          additionalInformation: {
            ipAddress: rootState.shoppingCart.userIpAddress,
          }
        }
      };
    }

    // Checkout at braintree.
    return braintreeDropin.requestPaymentMethod(options)

      // if payment at braintree was successful...
      .then(payload => {
        // if we're using threeDSecure and the liability hasn't shifted
        // we 
        if (getters.useThreeDSecure && payload.type === "CreditCard" && !payload.liabilityShifted) {
          throw new LiabilityNotShiftedError;
        }

        // ... also make a post to our backend to checkout the order...
        let checkoutOptions = {
          paymentNonce: payload.nonce,
          recaptchaChallenge: getters.getRecaptchaChallenge
        };

        Object.assign(checkoutOptions, rootState.account.edit);

        return axios
          .post(url, checkoutOptions)
          .catch(err => {
            throw new CheckoutError('Checkout Error', get(err, 'response'));
          });
      })

      /**
       * When that was successful, unwrap what's interesting for the 
       * caller and return that.
       */
      .then(response => {
        let data = get(response, 'data');
        return data;
      })

      .catch(error => {
        commit('setPurchaseInProgress', false);
        console.log(error);

        // re-throw error for anyone listening after this catch.
        throw error;
      });
  }
}

export default {
  actions,
  state,
  mutations,
  getters,
  namespaced: true
};