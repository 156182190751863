import {isGoogleTagManagerAvailable} from './tracking-util.js';

export const AUTHENTICATION_OPTION_NAMES = {
  LOGGED_IN: 'Logged in',
  NOT_LOGGED_IN: 'Guest'
};

/**
 * Tracks a Checkout with GTM.
 * 
 * @param {Object} cart the cart to track checkout of.
 * @param {Number} step the step number (if multiple).
 * @param {String} option any option to pass in the action field.
 * @param {Function} callback optional callback.
 */
export function trackCheckout(cart, step, option, callback) {
  if (!isGoogleTagManagerAvailable()) { return; }

  let actionField = { step };
  if (option) {
    actionField.option = option;
  }

  let data = {
    'event': 'checkout',
    'ecommerce': {
      'currencyCode': cart.currencyCode,
      'checkout': {
        actionField,
        'products': cart.items.map(item => ({
          'name': item.name,
          'id': item.productId,
          'price': ((item.price.campaignDecimal || item.price.normalDecimal) / item.quantity).toFixed(2),
          'quantity': item.quantity
        }))
      }
    }
  };

  if (callback) {
    data.eventCallback = callback;
  }

  dataLayer.push(data);
}

/**
 * Tracks a change in a checkout.
 * @param {Number} step the current step
 * @param {String} purchaseMode whether the user is logged in or not
 */
export function trackCheckoutOptions(step, option, callback) {
  if (!isGoogleTagManagerAvailable()) { return; }

  let data = {
    'event': 'checkoutOption',
    'ecommerce': {
      'checkout_option': {
        'actionField': {
          'step': step, 
          'option': option 
        }
      }
    }
  };

  if (callback) {
    data.eventCallback = callback;
  }

  dataLayer.push(data);
}