/**
 * This is a vue mixin that can be added to a component to make it track "impressions".
 * @see https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
 */

import {debounce} from '../util';

const impressionListeners = [];
const currencyMeta = document.querySelector('meta[property="softube:currency"]');

/**
 * Registers a set of options for product impression tracking.
 *
 * @param {Object} options the options
 * @param {DOMElement} options.element the element that should be tracked for impressions.
 * @param {Object} options.product the product to track impression of.
 * @param {String} options.list the list name to use.
 * @param {Number} options.position the position in the list.
 */
export function registerProductImpressionTracker(options) {
  // if it should be immedietly tracked, do it.. no need to add to the
  // listener array.
  if (shouldBeTracked(options)) {
    trackProductImpression(options);
    return;
  }

  impressionListeners.push(options);

  // first listener in the list, wire the events.
  if (impressionListeners.length === 1) {
    document.addEventListener('scroll', impressionChecker, { passive: true });
    document.addEventListener('touchmove', impressionChecker, { passive: true });
    document.addEventListener('resize', impressionChecker, { passive: true });
  }
}

/**
 * Removes the tracking of the provided element (if it is still being checked for)
 *
 * @param {DOMElement} element the element that is being tracked
 */
export function removeProductImpressionTracker(element) {
  let index = impressionListeners.findIndex(listener => listener.element === element);
  if (index !== -1) {
    impressionListeners.splice(index, 1);
  }

  // last listener in the list, no need for the events anymore.
  if (impressionListeners.length === 0) {
    document.removeEventListener('scroll', impressionChecker);
    document.removeEventListener('touchmove', impressionChecker);
    document.removeEventListener('resize', impressionChecker);
  }
}

/**
 * Checks the currently registered listeners,
 * whether any of them should be tracked as an impression.
 */
function checkProductsOnScreen() {
  for (let i = 0; i < impressionListeners.length; i++) {
    let listener = impressionListeners[i];
    if (!shouldBeTracked(listener)) { continue; }
    trackProductImpression(listener);
    removeProductImpressionTracker(listener.element)
    i--;
  }
}

/**
 * Checks whether the registered listener should
 * be tracked.
 * @param {Object} listener the listener to check.
 */
function shouldBeTracked({ element }) {
  let clientHeight = document.documentElement.clientHeight;
  let { top, bottom } = element.getBoundingClientRect();

  // just check whether either bottom or top is visible, this is a very generous way of tracking, just for starters.
  let isVisible = bottom >= 0 && top <= clientHeight;
  return isVisible;
}

/**
 *
 * @param {Object} listener the listener to track.
 */
function trackProductImpression({ product, list, position }) {
  let impression = {
    name: product.name,
    id: product.id,
    price: product.price,
    list,
    position
  };

  if (typeof(dataLayer) === 'undefined' || !('push' in dataLayer)) {
    return;
  }

  // push to the datalayer.
  dataLayer.push({
    'event': 'productImpression',
    'ecommerce': {
      'currencyCode': currencyMeta.content,
      'impressions': [impression]
    }
  });
}

/**
 * Create a debounced checked, no need to check more often than
 * that.
 */
let impressionChecker = debounce(function() {
  checkProductsOnScreen();
}, 100);
