
import SuiSearchProducts     from './search-products.vue';
import SuiSearchSupport      from './search-support.vue';
import SuiSearchContents     from './search-contents.vue';

export default [
  {
    name: 'Search:root',
    path: '/',
    redirect: '/all'
  },
  {
    name: 'Search:all',
    path: '/all',
    components: {
      products: SuiSearchProducts,
      support: SuiSearchSupport,
      contents: SuiSearchContents
    }
  },
  {
    name: 'Search:products',
    path: '/products',
    components: {
      products: SuiSearchProducts
    }
  },
  {
    name: 'Search:support',
    path: '/support',
    components: {
      support: SuiSearchSupport
    }
  },
  {
    name: 'Search:contents',
    path: '/contents',
    components: {
      contents: SuiSearchContents
    }
  }
];
