import axios from 'axios';
import { pick, omit, get } from '../../base/util';
import i18n from '../../i18n';

import {
  NAMESPACE as nsMessages,
  ACTION_TYPES as atMessages
} from './messages';
import Vue from 'vue';

export const NAMESPACE = 'account';
export const MUTATION_TYPES = {
  setAccountLoading: 'setAccountLoading',
  setCurrentAccount: 'setCurrentAccount',
  setEditFields: 'setEditFields',
  setLoginUsername: 'setLoginUsername',
  setLoginPassword: 'setLoginPassword',
  setSignedIn: 'setSignedIn'
};

export const ACTION_TYPES = {
  init: 'init',
  createAccount: 'createAccount',
  login: 'login',
  fetchAccountMeta: 'fetchAccountMeta',
  fetchCurrentAccount: 'fetchCurrentAccount',
  saveAccount: 'saveAccount',
  changePassword: 'changePassword',
  provisionIlokAccount: 'provisionIlokAccount',
  linkIlokId: 'linkIlokId',
  disconnectIlokId: 'disconnectIlokId'
};

export const GETTER_TYPES = {
  countryByCode: 'countryByCode'
};

/**
 * account
 * this store handles account properties.
 */

const namespaced = true;

// apply overrides if exists.
let overrides = typeof (apiOverrides) !== 'undefined' ? apiOverrides : {};
let { ACCOUNT_META, ACCOUNT, ACCOUNT_PASSWORD, ACCOUNT_ILOK, ACCOUNT_LOGIN, ACCOUNT_LOGOUT, ACCOUNT_SAVE } = overrides;

const fieldDefaults = {
  canPurchase: false,
  firstName: null,
  lastName: null,
  address: null,
  city: null,
  state: null,
  postcode: null,
  email: null,
  countryCode: null,
  phoneMobile: null,
  vatCode: null,
  vatNumber: null,
  iLokId: null,
  iLokToken: null,
  companyName: null,
  subscribeNewsletter: false,
  differentDeliveryAddress: false,
  deliveryFirstName: null,
  deliveryLastName: null,  
  deliveryAddress: null,
  deliveryCity: null,
  deliveryPostcode: null,
  deliveryCountryCode: null,
  deliveryState: null,
};

const state = {
  signedIn: false,
  suggestedIlokId: null,
  initialLoading: false,
  provisioning: false,
  current: {
    loading: false
  },
  edit: {},
  login: {
    username: null,
    password: null
  },
  password: {
    loading: false,
    oldPassword: null,
    password: null,
    repeatPassword: null
  },
  meta: {
    loading: false,
    app: {},
    countries: [],
    states: [],
    iLokOauthUri: null,
    allowNewsletterSignupOnAccount: true,
    addressIsRequired: false
  }
};

Object.assign(state.current, fieldDefaults);
Object.assign(state.edit, fieldDefaults);

/**
 * Mutations
 */

const mutations = {
  setInitialLoading(state, loading) {
    state.initialLoading = loading;
  },

  [MUTATION_TYPES.setCurrentAccount](state, account) {
    state.current = {
      ...state.current,
      ...account
    };
  },

  [MUTATION_TYPES.setEditFields](state, account) {
    state.edit = {
      ...state.edit,
      ...account
    };
  },

  setCurrentIlokId(state, iLokId) {
    state.current.iLokId = iLokId;
  },

  setCurrentIlokToken(state, iLokToken) {
    state.current.iLokToken = iLokToken;
  },

  setAccountMeta(state, { app, countries, iLokOauthUri, states, allowNewsletterSignupOnAccount, addressIsRequired }) {
    state.meta.app = { ...state.meta.app, ...app };
    state.meta.countries = countries;
    state.meta.iLokOauthUri = iLokOauthUri;
    state.meta.states = states;
    state.meta.allowNewsletterSignupOnAccount = allowNewsletterSignupOnAccount;
    state.meta.addressIsRequired = addressIsRequired;
  },

  setMetaLoading(state, loading) {
    state.meta.loading = loading;
  },

  [MUTATION_TYPES.setAccountLoading](state, loading) {
    state.current.loading = loading;
  },

  setProvisioning(state, provisioning) {
    state.provisioning = provisioning;
  },

  setSuggestedIlokId(state, iLokId) {
    state.suggestedIlokId = iLokId;
  },

  setCurrentField(state, field) {
    state.current[field[0]] = field[1];
  },

  setEditField(state, field) {
    state.edit[field[0]] = field[1];
  },

  setPasswordField(state, field) {
    state.password[field[0]] = field[1];
  },

  clearPassword(state) {
    state.password.oldPassword = null;
    state.password.password = null;
    state.password.repeatPassword = null;
  },

  setPasswordLoading(state, loading) {
    state.password.loading = loading;
  },

  [MUTATION_TYPES.setLoginUsername](state, username) {
    state.login.username = username;
  },

  [MUTATION_TYPES.setLoginPassword](state, password) {
    state.login.password = password;
  },

  [MUTATION_TYPES.setSignedIn](state, signedIn) {
    state.signedIn = signedIn;
  },

  copyToEdit(state) {
    let current = state.current;
    let json = JSON.stringify(current);
    let copy = JSON.parse(json);

    state.edit = Object.assign({}, state.edit, copy);
  },
};

/**
 * Getters
 */

const getters = {
  [GETTER_TYPES.countryByCode]: (state) => (countryCode) => {
    return state.meta.countries.find(c => c.id === countryCode);
  },
  [GETTER_TYPES.stateByCode]: (state) => (stateCode) => {
    return state.meta.states.find(c => c.id === stateCode);
  }
}

/**
 * Actions
 */

const actions = {

  /**
   * Combines both the required fetch calls.
   * @param {Object} param0 vuex-context
   * @return Promise
   */
  [ACTION_TYPES.init]({ dispatch, commit, state }) {
    commit('setInitialLoading', true);
    return Promise
      .all([
        dispatch('fetchAccountMeta'),
        dispatch('fetchCurrentAccount')
      ])
      .then(() => {
        return {
          account: state.current,
          meta: state.meta
        };
      })
      .finally(() => {
        commit('setInitialLoading', false);
      });
  },

  [ACTION_TYPES.fetchAccountMeta]({ commit }) {
    let endpoint = ACCOUNT_META || '/api/v1/accounts-meta';
    commit('setMetaLoading', true);
    return axios
      .get(endpoint)
      .then(response => {
        let locale = document.documentElement.lang;
        commit('setAccountMeta', omit(response.data, 'i18n'));
        i18n.mergeLocaleMessage(locale, get(response.data, 'i18n'))
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        commit('setMetaLoading', false);
      });
  },

  /**
   * Fetches the current account from the api.
   * @param {Object} param0 vuex-context 
   * @return {Promise} a promise for fetching the current account
   */
  [ACTION_TYPES.fetchCurrentAccount]({ commit }) {
    let endpoint = ACCOUNT || '/api/v1/accounts/me';
    commit(MUTATION_TYPES.setAccountLoading, true);
    return axios
      .get(endpoint)
      .catch(error => {
        console.log(error);
        commit(MUTATION_TYPES.setSignedIn, false);
      })
      .then(response => {
        let anonymous = !!get(response, 'data.anonymous');
        if (anonymous) {
          commit(MUTATION_TYPES.setSignedIn, false);
          return;
        }

        commit(MUTATION_TYPES.setCurrentAccount, response.data);
        commit(MUTATION_TYPES.setSignedIn, true);
      })
      .finally(() => {
        commit(MUTATION_TYPES.setAccountLoading, false);
      });
  },

  /**
   * Saves the current user.
   * @param {Object} param0 vuex-context
   * @return {Promise} completion
   */
  saveAccount({ dispatch, commit, state }, options = {}) {
    let endpoint = ACCOUNT_SAVE || '/api/v1/accounts/me';
    let fromCheckout = !!options.fromCheckout;
    commit('setAccountLoading', true);
    return axios
      .put(endpoint, { ...pick(state.edit, 'email', 'firstName', 'lastName', 'address', 'city', 'state', 'postcode', 'countryCode', 'phoneMobile', 'vatCode', 'vatNumber', 'companyName', 'subscribeNewsletter'), fromCheckout })
      .then(response => {
        commit('setCurrentAccount', response.data);
        dispatch(`${nsMessages}/${atMessages.showMessage}`, {
          icon: 'info',
          text: 'Your personal information was updated',
          type: 'success'
        }, { root: true });
      })
      .catch(error => {
        let message = error.response.data.message;
        dispatch(`${nsMessages}/${atMessages.showMessage}`, {
          icon: 'info',
          text: message,
          type: 'callout'
        }, { root: true });
      })
      .finally(() => {
        commit('setAccountLoading', false);
      });
  },

  [ACTION_TYPES.changePassword]({ dispatch, commit, state }) {
    let endpoint = ACCOUNT_PASSWORD || '/api/v1/accounts/me/password';
    commit('setPasswordLoading', true);
    return axios
      .put(endpoint, pick(state.password, 'oldPassword', 'password', 'repeatPassword'))
      .then(response => {
        commit('clearPassword');
        dispatch(`${nsMessages}/${atMessages.showMessage}`, {
          icon: 'info',
          text: 'Your password has been changed',
          type: 'success'
        }, { root: true });
      })
      .catch(error => {
        let message = error.response.data.message;
        dispatch(`${nsMessages}/${atMessages.showMessage}`, {
          icon: 'info',
          text: message,
          type: 'callout'
        }, { root: true });
      })
      .finally(() => {
        commit('setPasswordLoading', false);
      });
  },

  [ACTION_TYPES.provisionIlokAccount]({ commit }) {
    commit('setProvisioning', true);
    let endpoint = ACCOUNT_ILOK || '/api/v1/accounts/me/ilok';
    return axios
      .post(endpoint)
      .then(response => {
        if (response.data.redirectUrl) {
          // redirect to another page
          window.location = response.data.redirectUrl;
        } else {
          commit('setProvisioning', false);
          setTimeout(() => {
            commit('setCurrentIlokId', response.data.iLokId);
            commit('setCurrentIlokToken', response.data.iLokToken);
          }, 500);
          window.scrollTo(0,0);
        }
      })
      .catch(error => {
        var data = error.response.data;

        // account existed, suggest that is used for linking.
        if (data.errorCode === 601) {
          commit('setSuggestedIlokId', data.suggestedIlokId);
          return;
        }

        commit('setProvisioningError', data.message);
      });
  },

  // This function is just a convenience action that redirects the
  // user to the iLok OAuth2 endpoint.
  [ACTION_TYPES.linkIlokId]({ state }, iLokId) {
    window.location = `${state.meta.iLokOauthUri}&login_id=${encodeURIComponent(iLokId)}`;
  },

  [ACTION_TYPES.disconnectIlokId]({ commit }) {
    let endpoint = ACCOUNT_ILOK || '/api/v1/accounts/me/ilok';
    return axios
      .delete(endpoint)
      .then(response => {
        commit('setCurrentIlokId', null);
        commit('setCurrentILokToken', null);
      })
      .catch(error => {
        console.error(error);
      });
  },

  login({ commit, dispatch }, { username, password }) {
    let endpoint = ACCOUNT_LOGIN || '/api/v1/accounts/login';
    commit(MUTATION_TYPES.setAccountLoading, true);
    return axios
      .post(endpoint, { username, password })
      .then(response => {
        commit(MUTATION_TYPES.setEditFields, fieldDefaults);
        commit(MUTATION_TYPES.setCurrentAccount, response.data);
        commit(MUTATION_TYPES.setSignedIn, true);
      })
      .catch(error => {
        console.error(error);
        throw error;
      })
      .finally(() => {
        commit(MUTATION_TYPES.setAccountLoading, false);
      });
  },

  logout({ commit, dispatch }) {
    let endpoint = ACCOUNT_LOGOUT || '/api/v1/accounts/logout';
    commit(MUTATION_TYPES.setAccountLoading, true);
    return axios
      .post(endpoint)
      .then(() => {
        commit('setSignedIn', false);
        commit('setCurrentAccount', fieldDefaults);
        commit('copyToEdit');
      })
      .finally(() => {
        commit(MUTATION_TYPES.setAccountLoading, false);
      });
  }
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced
};
