
import {debounce} from './util';
import EventEmitter from 'events';

/**
 * this class tracks the current breakpoint and exposes it in a `current` property.
 * it also allows for event emitting when it changes breakpoint.
 * @extend EventEmitter
 */
class Breakpoints extends EventEmitter {

  constructor() {
    super();
    this.refresh(true);
  }

  refresh(silent) {
    var bodyAfter = window.getComputedStyle(document.querySelector('body'), ':after');
    var bp = bodyAfter.getPropertyValue('content').replace(/[\"\']*/g, '');
    if (bp !== this.current) {
      this.current = bp;
      if (!silent) { this.emit('change', this.current); }
    }
  }
}

var instance = new Breakpoints;
window.addEventListener('resize', debounce(() => instance.refresh(), 100), { passive: true });

export default instance;
