import Vue from 'vue';

import {
  setSerializedObject, 
  getDeserializeObject
} from '../../base/localStorage';

let idCounter = 0;
const MESSAGES_KEY = 'sui-seen-messages';

export const NAMESPACE = 'modals';

export const MUTATION_TYPES = {
  dismissModal: 'dismissModal',
  showModal: 'showModal',
  updateModalContent: 'updateModalContent'
};

export const ACTION_TYPES = {
  showMessageModals: 'showMessageModals'
}

const state = {
  show: false,
  closeable: false,
  light: false,
  id: '',
  type: '',
  dismissedPermanently: false,
  content: {}
};

const mutations = {
  [MUTATION_TYPES.dismissModal](state) {
    if (state.dismissedPermanently && state.id) {
      let seenMessages = getDeserializeObject(MESSAGES_KEY, { defaultValue: [], fallbackToCookie: true });
      seenMessages.push(state.id);
      setSerializedObject(MESSAGES_KEY, seenMessages, { fallbackToCookie: true });
    }

    state.show = false;
    state.type = '';
    state.light = false;
    Vue.set(state, 'content', {});
  },

  [MUTATION_TYPES.showModal](state, modal) {
    if (state.show) { return; }
    state.show = true;
    state.id = modal.id;
    state.closeable = modal.closeable;
    state.light = modal.light;
    state.dismissedPermanently = modal.dismissedPermanently;
    state.type = modal.type;
    Vue.set(state, 'content', modal.content);
  },

  [MUTATION_TYPES.updateModalContent](state, content) {
    Vue.set(state, 'content', content);
  }
};

const actions = {

  /**
   * Shows a modal to the user if he hasn't already dismissed the 
   * message.
   * 
   * @param {Object} state 
   * @param {Object} payload 
   * @param {Object} payload.modals 
   * @param {Object} payload.adminMode
   */
  [ACTION_TYPES.showMessageModals]({commit}, { modals, adminMode }) {
    let seenMessages = getDeserializeObject(MESSAGES_KEY, { defaultValue: [], fallbackToCookie: true });
    for (const modal of modals) {
      if (adminMode || !modal.dismissedPermanently || (modal.dismissedPermanently && !seenMessages.includes(modal.id))) {
        commit(MUTATION_TYPES.showModal, modal);
        break;
      }
    }
  }
}

export default {
  state,
  actions,
  mutations,
  namespaced: true
};