import axios from 'axios';
import {
  NAMESPACE as nsMessages,
  ACTION_TYPES as atMessages 
} from './messages'

export const NAMESPACE = 'newsletter';
export const MUTATIONS = {
  setLoading: 'setLoading'
};
export const ACTION_TYPES = {
  subscribe: 'subscribe'
};

/**
 * page footer store.
 * NOTE: maybe this should instead be a "newsletter" store or similar.
 */

// apply overrides if exists.
let overrides = typeof(apiOverrides) !== 'undefined' ? apiOverrides : {};
let { NEWSLETTER } = overrides;

const state = {
  loading: false
};

const mutations = {
  [MUTATIONS.setLoading](state, loading) {
    state.loading = loading;
  }
};

const actions = {

  [ACTION_TYPES.subscribe]({commit, dispatch}, email) {
    const url = NEWSLETTER || '/api/v1/newsletter-subscription'
    commit('setLoading', true);

    return axios
      .post(url, { email })
      .then(response => {
        let message = {
          icon: 'info',
          text: response.data.message,
          type: 'success'
        };

        dispatch(`${nsMessages}/${atMessages.showMessage}`, message, { root: true });
      })
      .catch(error => {
        if (!error.response && !error.response.data) { return; }
        let message = {
          icon: 'info',
          text: error.response.data.message,
          type: 'callout'
        };
        dispatch(`${nsMessages}/${atMessages.showMessage}`, message, { root: true });
      })
      .finally(() => {
        commit('setLoading', false);
      });
  }
};

export default {
  namespaced: true,
  actions,
  mutations,
  state
};
