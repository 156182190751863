/**
 * This mixin adds functionality to track product
 * clicks.
 * @see https://developers.google.com/tag-manager/enhanced-ecommerce#product-clicks
 */

 import {isGoogleTagManagerAvailable} from './tracking-util.js';

/**
 * Tracks the data provided with Google Enhanced Ecommerce Tracking.
 * @param {Object} data
 * @param {Function} callback
 */
export default function trackProductClick({ product, list, position }, callback) {
  // no google tag manager, just run the callback if it's set.
  if (!isGoogleTagManagerAvailable()) {
    if (typeof(callback) !== 'function') { return; }
    return callback();
  }

  dataLayer.push({
    'event': 'productClick',
    'ecommerce': {
      'click': {
        'actionField': {'list': list},
        'products': [{
          'name': product.name,
          'id': product.id,
          'price': product.price,
          'position': position
          }]
        }
      },
      'eventCallback': callback
  });
}
