
/**
 * @module util.js
 *
 * this file contains utility functions that is used in multiple
 * places.
 */

/**
 * takes a function and debounces it
 * @param {function} fn - the function to debounce
 * @param {Number} delay - the debounce rate.
 * @return {function} the debounced function.
 */
export function debounce(fn, delay) {
  var timer = null;
  return function () {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

export function getParamByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function pick(o, ...props) {
  return Object.assign({}, ...props.map(prop => ({[prop]: o[prop]})));
}

export function omit(o, ...props) {
  return Object
    .assign({}, 
      ...Object
        .entries(o)
        .filter(([key]) => !props.includes(key))
        .map(([key, value]) => ({[key]: value })));
}

export function get(o, ...args) {
  if (!o) { return undefined; }
  let fieldName = args.shift();
  if (fieldName.includes('.')) { return get.apply(this, [o, ...fieldName.split('.'), ...args]); }
  if (!(fieldName in o)) { return undefined; }
  if (args.length) { return get.apply(this, [o[fieldName], ...args]); }
  return o[fieldName];
}

export function testLocalStorage() {
  var test = 'test';
  try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
  } catch(e) {
      return false;
  }
}