import axios from 'axios';

/**
 * contents
 * this vuex module will fetch contents from the website to use
 * in for instance a search.
 */

const namespaced = true;

// apply overrides if exists.
let overrides = typeof(apiOverrides) !== 'undefined' ? apiOverrides : {};
let { CONTENTS } = overrides;

const state = {
  query: null,
  pageNumber: 1,
  pageSize: 20,
  loading: false,
  items: [],
  totalResults: 0
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },

  setQuery(state, query) {
    state.query = query;
  },

  setItems(state, items) {
    state.items = items;
  },

  setTotalResults(state, count) {
    state.totalResults = count;
  }
};

let cancelToken;

const actions = {

  fetchResult({commit, state}) {
    let endpoint = CONTENTS || '/api/v1/contents';
    commit('setLoading', true);

    if (cancelToken) {
      cancelToken();
      cancelToken = null;
    }

    let params = {
      query: state.query,
      pageNumber: state.pageNumber,
      pageSize: state.pageSize
    };

    axios.get(endpoint, {
      cancelToken: new axios.CancelToken(c => cancelToken = c),
      params
    })
      .catch(error => {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      })
      .then(response => {
        if (!response) { return; }
        commit('setItems', response.data.result);
        commit('setTotalResults', response.data.totalResults);
      })
      .finally(() => {
        commit('setLoading', false);
        cancelToken = null;
      });
  }

};

export default {
  state,
  mutations,
  actions,
  namespaced
};
